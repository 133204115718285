<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useModelsStore, useActionsStore } from '@/stores';
import { VueJsonPretty } from '@/components';
import { useDateFormat } from '@/composables';
import { router } from '@/router';
import { useRoute } from "vue-router";
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const actionsStore = useActionsStore();
const modelsStore = useModelsStore();
const { model, isLoaded } = storeToRefs(modelsStore);
const { actions } = storeToRefs(actionsStore);
// const userid = authStore.user.userid;
const route = useRoute();
const id = route.params.id;

// console.log("route params", route.params);

// const rowClass = (data) => {
//     return [{
//             'text-green-500': data.request.method === 'GET',
//             'text-yellow-500': data.request.method === 'POST',
//             'text-cyan-500': data.request.method === 'PUT',
//             'text-indigo-500': data.request.method === 'PATCH'
//         }];
// };

// const buttonColor = (data) => {
//     return countTotalExecutionsbyPrediction(getIndexKey(data)) > 0 ? 'success' : 'danger';
// };

const modelActions = computed(() => {
    return actions.value.filter((s) => s._modelid == id);
});

const countActionbyPrediction = (predictionIndex) => {
    return modelActions.value.filter((obj) => obj.predictionIndex === predictionIndex).length;
};

const TotalExecutionsbyPrediction = (predictionIndex) => {
    return modelActions.value.filter((obj) => obj.predictionIndex === predictionIndex).reduce((acc, cur) => cur.timesExecuted + acc, 0);
};


const lastExecutionDatebyPredictionIndex = (predictionIndex) => {
    let executionResult = modelActions.value.filter((obj) => obj.predictionIndex === predictionIndex);
    if (executionResult.length === 0) {
        return 'No Data';
    } else {
        return useDateFormat(executionResult.reduce((a, b) => (a.lastExecuted > b.lastExecuted ? a : b)).lastExecuted);
    }
};



const lastConfigurationDatebyPredictionIndex = (predictionIndex) => {
    let executionResult = modelActions.value.filter((obj) => obj.predictionIndex === predictionIndex);
    if (executionResult.length === 0) {
        return 'No Data';
    } else {
        return useDateFormat(executionResult.reduce((a, b) => (a.timestamp > b.timestamp ? a : b)).timestamp);
    }
};




const getIndexKey = (prediction) => {
    let predValue = model.value.attributes.metadata.prediction.domain.values;
    //  console.log('predValue = ', predValue);
    let indexkey = Object.keys(predValue).find((key) => predValue[key] == prediction);
    //  console.log('indexkey = ', indexkey);
    return parseInt(indexkey);
};

const onRowSelect = (event) => {
    console.log('onRowSelect selectedInput active', event);
    toast.add({ severity: 'info', summary: 'Selected Outcome', detail: event.data, life: 3000 });
    let trigger = getIndexKey(event.data);
    //  modelActionIndex.value = parseInt(trigger);
    console.log('onRowSelect trigger ', trigger);
    router.push({ path: `/actions/modelactions/${id}/trigger/${trigger}` });
    // router.push({ path: `/actions/modelactions/trigger` });
};

</script>

<template>
    <!-- info / action Bar -->
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card">
                <Button label="Active" severity="success" class="mb-2 mr-2" />
                <Button label="Warning" severity="warning" class="mb-2 mr-2" />
                <Button label="Not Ready" severity="danger" class="mb-2 mr-2" />
            </div>
        </div>
    </div>

    <!-- loading .... -->
    <ProgressSpinner v-if="!isLoaded" aria-label="Loading" />
    <!-- only when model loaded - model info - model inputs -->
    <template v-if="isLoaded">
        <!-- Model Info - logs, full cycle test, decisions,  -->
        <div class="card">
            <Accordion :activeIndex="0">
                <AccordionTab>
                    <template #header>
                        <span> Model Information -  {{  model.attributes.name }}</span>
                    </template>
                    <div class="grid">
                        <div class="col-12 md:col-6">
                            <Card>
                                <template #title>Model Status</template>
                                <template #content>
                                    <p class="m-0">
                                        Data Received -
                                        {{ useDateFormat(model.attributes.inputsLastChange) }}
                                    </p>
                                    <p class="m-0">
                                        Decision Tested -
                                        <i class="pi pi-thumbs-up-fill" style="font-size: 1rem; color: greenyellow"></i>
                                    </p>
                                    <p class="m-0">
                                        Actions Triggered -
                                        {{ useDateFormat(model.attributes.triggerLastChange) }}
                                    </p>
                                    <p class="m-0">
                                        Actions Tested -
                                        <i class="pi pi-thumbs-up-fill" style="font-size: 1rem; color: greenyellow"></i>
                                    </p>
                                </template>
                            </Card>
                        </div>
                        <div class="col-12 md:col-6">
                            <Card>
                                <template #title>Model Info</template>
                                <template #content>
                                    <p class="m-0">id - {{ model.id }}</p>
                                    <p class="m-0">Author - {{ model.attributes.publisher }}</p>
                                    <p class="m-0">
                                        Model Published -
                                        {{ useDateFormat(model.attributes['publish-date']) }}
                                    </p>
                                    <p class="m-0">
                                        Audit Trail LogRef -
                                        {{ model.attributes.lastLogRef }}
                                    </p>
                                </template>
                            </Card>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>

        <Divider />

        <div class="card">
            <DataTable :value="model.attributes.metadata.prediction.domain.values" selectionMode="single" paginator
                stripedRows :rows="10" :rowsPerPageOptions="[10, 20, 50]" tableStyle="min-width: 50rem"
                :loading="!isLoaded" @rowSelect="onRowSelect">
                <template #header>
                    <div class="flex flex-wrap items-center justify-between gap-2">
                        <span class="text-xl font-bold">Decision {{ model.attributes.metadata.prediction.question
                            }}</span>
                    </div>
                </template>

                <template #empty> No Input found. </template>
                <template #loading> Loading Model Input data. Please wait. </template>
                <Column header="AI Decision">
                    <template #body="{ data }">
                        {{ data.substring(0, 20) }}
                    </template>
                </Column>
                <Column style="flex: 0 0 3rem" header="Actions">
                    <template #body="{ data }">
                        {{ countActionbyPrediction(getIndexKey(data)) }}
                    </template>
                </Column>
                <Column style="flex: 0 0 3rem" header="Total Executions">
                    <template #body="{ data }">
                        {{ TotalExecutionsbyPrediction(getIndexKey(data)) }}
                    </template>
                </Column>
                <Column style="flex: 0 0 3rem" header="Last Executed">
                    <template #body="{ data }">
                        {{ lastExecutionDatebyPredictionIndex(getIndexKey(data)) }}
                    </template>
                </Column>
                <Column style="flex: 0 0 3rem" header="Last Config">
                    <template #body="{ data }">
                        {{ lastConfigurationDatebyPredictionIndex(getIndexKey(data)) }}
                    </template>
                </Column>
            </DataTable>
        </div>



    </template>

    <!-- <Divider />


  <VueJsonPretty :selectedItem="modelsStore.modelPopulated" :jsonPrettyName="modelPopulatedPrettyName" />
  <VueJsonPretty :selectedItem="selectedInput" :jsonPrettyName="modelPrettyName" /> -->
</template>
