<script setup>
    import { storeToRefs } from 'pinia';
    import { ref, watch, computed, onMounted } from 'vue';
    import { useActionsStore, useAlertStore, useConnectorsStore, useTokensetsStore } from '@/stores';
    import { useRoute } from 'vue-router';
    import { router } from '@/router';
    import { FilterMatchMode } from 'primevue/api';
    import { VueJsonPretty, ActionVariables, ActionHeaders, ActionQuery, ActionFormData } from '@/components';
    import { useDateFormat, useMarkdown } from '@/composables';
    import { useToast } from 'primevue/usetoast';
    const toast = useToast();
    const route = useRoute();
    const id = route.params.id;
    const actionsStore = useActionsStore();
    const alertStore = useAlertStore();
    const { alert } = storeToRefs(alertStore);
    const connectorsStore = useConnectorsStore();
    const selectedAPIPrettyName = ref('Action Details');
    const selectedPopulatedAPIPrettyName = ref('Populated Action Details');
    const selectedResponsePrettyName = ref('Response Details');
    const actionResponsesPrettyName = ref('Action Responses');
    const selectedResponseName = ref('Selected Response');
    const tokensetsStore = useTokensetsStore();
    const { tokensets, tokenset } = storeToRefs(tokensetsStore);
    const { updateToken, getTokenset, getTokensets } = tokensetsStore;
    const {
        // data
        action,
        actionPopulated,
        response,
        responses,
        // control
        isLoaded,
        testAllowed,
        // status
        actionItemAllocated,
        discoveryURLOK,
        isTokenManager,
        isTokenClient,
        // change indicators
        hostChanged,
        pathChanged,
        // initial data
        compareHost,
        comparePath,
        // helpers
        itsAString,
        errorOnResult,
        responseAvailable,
        isProtocol,
        bodyFormdata,
        bodyJson,
        // auth
        newAuthSettingType,
        authSettingType,
        oauth2PasswordCredentials,
        oauth2ClientCredentials,
        passwordIndex,
        usernameIndex,
        apivalueIndex,
        apikeyIndex,
        // messages
        actionMessages,
        actionActivePanel,
        // other
        parsedVariables,
        postMethod
    } = storeToRefs(actionsStore);
    const { actionTest, checkConfig, saveAction, clone, deleteAction, saveHost, savePath, deallocateAction, checkDiscoveryURL, updateAction, createTokenClient } = actionsStore;
    const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const selectedItem = ref();

    onMounted(() => {
        actionMessages.value = [];
        getTokensets();
    });

    const isLogAvailable = (logRefNumber) => {
        return logs.value.some(log => log.meta.stack.logRefNumber === logRefNumber);
    };


    const protocolOptions = ref([
        { text: 'HTTP', value: 'http' },
        { text: 'HTTPS', value: 'https' }
    ]);
    const display = ref(false);
    const open = () => {
        display.value = true;
    };
    const editingRows = ref([]);

    const editingBody = ref(false);
    const editingCard = ref(false);

    const close = () => {
        display.value = false;
    };
    const methodOptions = ref([
        { text: 'GET', value: 'GET' },
        { text: 'POST', value: 'POST' },
        { text: 'PUT', value: 'PUT' },
        { text: 'PATCH', value: 'PATCH' }
    ]);

    const authOptions = ref([
        { text: 'NONE', value: 'noauth' },
        { text: 'BASIC', value: 'basic' },
        { text: 'BEARER', value: 'bearer' },
        { text: 'APIKEY', value: 'apikey' },
        { text: 'OAUTH2', value: 'oauth2' }
    ]);


    const bodyOptions = ref([
        { text: 'NONE', value: 'none' },
        { text: 'JSON', value: 'raw' },
        { text: 'FORMDATA', value: 'formdata' }
    ]);

    const addTokenTo = ref([
        { text: 'Add Token to Header', value: 'header' },
        { text: 'Add Token to Url', value: 'url' }
    ]);

    const clientAuthentication = ref([
        { text: 'Send Basic Auth Header', value: 'header' },
        { text: 'Send in Body', value: 'body' }
    ]);

    const grantType = ref([
        { text: 'Client Authentication', value: 'client_credentials' },
        { text: 'Password Authentication', value: 'password_credentials' }
    ]);

    const deformatOptions = ref([
        { text: 'NO', value: false },
        { text: 'YES', value: true }
    ]);

    const tokenClient = ref([
        { text: 'NO', value: false },
        { text: 'YES', value: true }
    ]);


    const selectionTypes = ref([
        { text: 'SINGLE', value: 'single' },
        { text: 'MULTIPLE', value: 'multiple' }
    ]);

    const yesnoOptions = ref([
        { text: 'NO', value: false },
        { text: 'YES', value: true }
    ]);

    const deformatedResult = computed(() => {
        return actionsStore.action.deformat.deformatstatus ? parseFloat(actionsStore.action.lastresult) : actionsStore.action.lastresult;
    });

    const tokenSelected = computed(() => {
        return !(action.value.tokenset === null);
    });

    const variableSelected = computed(() => {
        return !(action.value.tokenVariableKey === null);
    });

    const actionSave = async () => {
        console.log('actionSave action =', action.value);
        await saveAction(action.value._id, action.value);
        toast.add({ severity: 'info', summary: 'Action Saved', life: 3000 });
    }
    const saveClientToken = async () => {
        isLoaded.value = false;
        await createTokenClient();
        console.log('Action.vue saveClientToken', action.value._id);
        toast.add({ severity: 'info', summary: 'Token Linked', life: 3000 });
    }
    function cancelConfig() {
        actionsStore.actionSelected = false;
        router.push({ path: `/actions` });
    }

    async function latestLogs(id) {
        console.log('latestLogs logReference =', id);
        router.push({ path: `/logs/logref/${id}` });
    }


    const onRowEditSave = (event) => {
        let { newData, index } = event;
        action.variable[index] = newData;
    };

    const rowClass = (data) => {
        return [{ 'text-green-500': data.code < 400, 'text-red-500': data.code > 399 }];
    };

    watch(newAuthSettingType, (newnewAuthSettingType) => {
        if (newnewAuthSettingType !== actionsStore.authSettingType) {
            actionsStore.changeAuth();
        }
    });

    watch(compareHost, (newcompareHost) => {
        if (newcompareHost !== actionsStore.action.item.request.url.host.join('.')) {
            actionsStore.hostChanged = true;
        } else {
            actionsStore.hostChanged = false;
        }
    });

    watch(comparePath, (newcomparePath) => {
        if (newcomparePath !== actionsStore.action.item.request.url.path.join('/')) {
            actionsStore.pathChanged = true;
        } else {
            actionsStore.pathChanged = false;
        }
    });
</script>

<template>
    <!-- info / action Bar -->
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card">
                <div class="flex flex-wrap items-center justify-between gap-2">
                    <span class="text-xl font-bold">Action Management</span>
                    <Button label="Cancel" severity="help" @click="cancelConfig()" class="mb-2 mr-2" />
                    <Button label=" Update & Test" severity="success" :disabled="!testAllowed"
                        @click="actionTest(action._id)" class="mb-2 mr-2" />
                    <Button label="Preview Setup " severity="info" @click="checkConfig(action._id)" class="mb-2 mr-2" />
                    <Button label="Save" severity="success" @click="actionSave()" class="mb-2 mr-2" />
                    <Button label="Clone" severity="help" @click="clone(action._id)" class="mb-2 mr-2" />
                    <Button v-if="!actionItemAllocated" label="Delete " severity="danger"
                        @click="deleteAction(action._id)" class="mb-2 mr-2" />
                    <Button v-if="actionItemAllocated" label="Release " severity="warning"
                        @click="deallocateAction(action._id)" class="mb-2 mr-2" />
                </div>
            </div>
        </div>
    </div>

    <!-- loading .... -->
    <ProgressSpinner v-if="!isLoaded" aria-label="Loading" />
    <!-- only when model loaded - model info - model inputs -->
    <template v-if="isLoaded">
        <!-- Model Info - logs, full cycle test, decisions,  -->
        <div class="grid">

            <div v-if="!editingCard" class="col-12 md:col-6">
                <div class="card">
                    <Card style="overflow: hidden">
                        <template #header> </template>
                        <template #title>{{ action.name }}</template>

                        <template #content>
                            <ScrollPanel style="width: 100%; height: 150px">
                                <p class="m-0">
                                <div v-html="useMarkdown(action.description)"></div>
                                </p>
                            </ScrollPanel>

                        </template>
                        <template #footer>
                            <div class="flex gap-4 mt-1">
                                <Button label="Edit" class="mb-2 mr-2" @click="editingCard = !editingCard" />
                            </div>
                        </template>
                    </Card>
                </div>
            </div>
            <div v-else class="col-12 md:col-6">
                <div class="card">
                    <Card style="overflow: hidden">
                        <template #header> </template>
                        <template #title>
                            <InputText id="name2" type="text" v-model="action.name" />
                        </template>

                        <template #content>
                            <ScrollPanel style="width: 100%; height: 150px">
                                <Textarea v-model="action.description" rows="6" cols="50" />
                            </ScrollPanel>

                        </template>
                        <template #footer>
                            <div class="flex gap-4 mt-1">
                                <Button label="Edit" class="mb-2 mr-2" @click="editingCard = !editingCard" />
                            </div>
                        </template>
                    </Card>
                </div>
            </div>
            <div v-if="bodyJson && !editingBody" class="col-12 md:col-6">
                <div class="card">
                    <Card style="overflow: hidden">
                        <template #header> </template>
                        <template #title>JSON Body</template>

                        <template #content>
                            <ScrollPanel style="width: 100%; height: 200px">
                                <p class="m-0">
                                    {{ action.item.request.body.raw }}
                                </p>
                            </ScrollPanel>

                        </template>
                        <template #footer>
                            <div class="flex gap-4 mt-1">
                                <Button label="Edit" class="mb-2 mr-2" @click="editingBody = !editingBody" />
                            </div>
                        </template>
                    </Card>
                </div>
            </div>
            <div v-if="bodyJson && editingBody" class="col-12 md:col-6">
                <div class="card">
                    <Card style="overflow: hidden">
                        <template #header> </template>
                        <template #title>JSON Body</template>

                        <template #content>
                            <ScrollPanel style="width: 100%; height: 200px">
                                <p class="m-0">
                                    <Textarea v-model="action.item.request.body.raw" rows="6" cols="50" />
                                </p>
                            </ScrollPanel>

                        </template>
                        <template #footer>
                            <div class="flex gap-4 mt-1">
                                <Button label="Edit" class="mb-2 mr-2" @click="editingBody = !editingBody" />
                            </div>
                        </template>
                    </Card>
                </div>
            </div>

            <div class="col-12">
                <div class="card">
                    <Accordion v-model:activeIndex="actionActivePanel">
                        <AccordionTab header="Url & Parameters">
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-6">
                                    <label for="rawurl">Raw Url</label>
                                    <InputText id="rawurl" type="text" v-model="action.rawUrl" disabled
                                        :invalid="hostChanged || pathChanged" />
                                </div>
                                <div class="field col-12 md:col-6">
                                    <label for="rawquery">Raw Query</label>
                                    <InputText id="rawquery" type="text" disabled v-model="action.rawBaseQuery"
                                        :invalid="hostChanged || pathChanged" />
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Feedback">
                            <transition-group name="p-message" tag="div">
                                <Message v-for="msg of actionMessages" :key="msg.id" :severity="msg.severity">{{
                                    msg.content }}
                                </Message>
                            </transition-group>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>



            <div class="col-12">
                <div class="card">
                    <TabView>
                        <TabPanel header="Config & Testing">
                            <div class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-2">
                                            <label for="method">Method</label>
                                            <Dropdown id="method" v-model="action.item.request.method"
                                                :options="methodOptions" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div class="field col-12 md:col-2">
                                            <label for="protocol">Protocol</label>
                                            <Dropdown id="protocol" v-model="action.item.request.url.protocol"
                                                :options="protocolOptions" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="horizontal-buttons" class="block mb-2">
                                                TimeOut m/s
                                            </label>
                                            <InputNumber v-model="action.timeOut" inputId="horizontal-buttons"
                                                showButtons buttonLayout="horizontal" :step="1000">
                                                <template #incrementbuttonicon>
                                                    <span class="pi pi-plus" />
                                                </template>
                                                <template #decrementbuttonicon>
                                                    <span class="pi pi-minus" />
                                                </template>
                                            </InputNumber>
                                        </div>
                                        <div v-if="postMethod" class="field col-12 md:col-2">
                                            <label for="bodytype">Body</label>
                                            <Dropdown id="bodytype" v-model="action.item.request.body.mode"
                                                :options="bodyOptions" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div class="field col-12 md:col-12">
                                            <label for="url1">Url</label>
                                            <InputText id="url1" type="text" disabled v-model="action.baseUrl" />
                                        </div>
                                        <div class="field col-12 md:col-5">
                                            <label for="host1">Host</label>
                                            <InputText id="host1" type="text" v-model="compareHost"
                                                :invalid="hostChanged" />
                                        </div>
                                        <div class="field col-12 md:col-1">
                                            <Button v-if="hostChanged" severity="success" rounded icon="pi pi-check"
                                                @click="saveHost()" />
                                        </div>
                                        <div class="field col-12 md:col-5">
                                            <label for="path1">Path</label>
                                            <InputText id="path1" type="text" v-model="comparePath"
                                                :invalid="pathChanged" />
                                        </div>
                                        <div class="field col-12 md:col-1">
                                            <Button v-if="pathChanged" severity="success" rounded icon="pi pi-check"
                                                @click="savePath()" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Authorization Control-->
                            <div class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-2">
                                            <label for="authtype1">Authorization</label>
                                            <Dropdown id="authtype1" v-model="newAuthSettingType" :options="authOptions"
                                                optionLabel="text" optionValue="value" placeholder="Select One">
                                            </Dropdown>
                                        </div>
                                        <div v-if="!isTokenManager" class="field col-12 md:col-2">
                                            <label for="tokenclient">Use Token Manager. </label>
                                            <Dropdown id="tokenclient" v-model="action.tokenClient"
                                                :options="tokenClient" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div v-if="action.tokenClient" class="field col-12 md:col-2">
                                            <label for="tokensets">Token Manager</label>
                                            <Dropdown id="tokensets" v-model="action.tokenset" :options="tokensets"
                                                optionLabel="id_token" optionValue="_id"
                                                placeholder="Select Token Manager" class="w-full md:w-56" />
                                        </div>
                                        <div v-if="action.tokenClient" class="field col-12 md:col-2">
                                            <label for="tokenvariable">Token Variable</label>
                                            <Dropdown id="tokenvariable" v-model="action.tokenVariableKey"
                                                :options="action.variable" optionLabel="key" optionValue="key"
                                                placeholder="Token Variable" class="w-full md:w-56" />
                                        </div>
                                        <div v-if="(tokenSelected && variableSelected)" class="field col-12 md:col-2">
                                            <label for="savetoken">Save Token</label>
                                            <Button id="savetoken" label="Save" severity="success" class="mb-2 mr-2"
                                                @click="saveClientToken()" />
                                        </div>
                                        <div v-if="authSettingType == 'oauth2'" class="field col-12 md:col-3">
                                            <label for="granttype1">Grant Type</label>
                                            <Dropdown id="granttype1" v-model="action.item.request.auth.oauth2[1].value"
                                                :options="grantType" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div v-if="authSettingType == 'oauth2' && oauth2ClientCredentials"
                                            class="field col-12 md:col-3">
                                            <label for="clientauth">Client Authentication</label>
                                            <Dropdown id="clientauth" v-model="action.item.request.auth.oauth2[8].value"
                                                :options="clientAuthentication" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div v-if="authSettingType == 'oauth2' && oauth2ClientCredentials"
                                            class="field col-12 md:col-3">
                                            <label for="addtoken">Add Token to?</label>
                                            <Dropdown id="addtoken" v-model="action.item.request.auth.oauth2[7].value"
                                                :options="addTokenTo" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                    </div>

                                    <!-- Authorization Type  Basic-->
                                    <div v-if="authSettingType == 'basic'" class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-3">
                                            <label for="username1">Username</label>
                                            <InputText id="username1" type="text"
                                                v-model="action.item.request.auth.basic[usernameIndex].value" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="password1">Password</label>
                                            <InputText id="password1" type="text"
                                                v-model="action.item.request.auth.basic[passwordIndex].value" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="authorization1">Authorization</label>
                                            <InputText id="authorization1" type="text"
                                                v-model="parsedVariables.authHeaderKey" disabled />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="credentials1">Encoded Credentials</label>
                                            <InputText id="credentials1" type="text"
                                                v-model="parsedVariables.authHeaderValue" disabled />
                                        </div>
                                    </div>

                                    <!-- Authorization Type  Bearer-->
                                    <div v-if="authSettingType == 'bearer'" class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-4">
                                            <label for="token1">Token</label>
                                            <InputText id="token1" type="text"
                                                v-model="action.item.request.auth.bearer[0].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="authorization2">Authorization</label>
                                            <InputText id="authorization2" type="text"
                                                v-model="parsedVariables.authHeaderKey" disabled />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="token2">Bearer / Token</label>
                                            <InputText id="token2" type="text" v-model="parsedVariables.authHeaderValue"
                                                disabled />
                                        </div>
                                    </div>

                                    <!-- Authorization Type API Key-->
                                    <div v-if="authSettingType == 'apikey'" class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-3">
                                            <label for="apiheader">API Header Key</label>
                                            <InputText id="apiheader" type="text"
                                                v-model="action.item.request.auth.apikey[apikeyIndex].value" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="apitoken">API Header Value</label>
                                            <InputText id="apitoken" type="text"
                                                v-model="action.item.request.auth.apikey[apivalueIndex].value" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="apiheader2">API Header</label>
                                            <InputText id="apiheader2" type="text"
                                                v-model="parsedVariables.authHeaderKey" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="apitoken2">API Token</label>
                                            <InputText id="apitoken2" type="text"
                                                v-model="parsedVariables.authHeaderValue" />
                                        </div>
                                    </div>

                                    <!-- Authorization Type  oauth2-->
                                    <div v-if="authSettingType == 'oauth2'" class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-4">
                                            <label for="clientid1">Client ID</label>
                                            <InputText id="clientid1" type="text"
                                                v-model="action.item.request.auth.oauth2[5].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="secret1">Client Secret</label>
                                            <InputText id="secret1" type="text"
                                                v-model="action.item.request.auth.oauth2[4].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="discovery">Auth Endpoint Discovery</label>
                                            <InputGroup>
                                                <InputText id="discovery" type="text" v-model="action.discoveryURL"
                                                    :invalid="!discoveryURLOK" />
                                                <Button icon="pi pi-check" severity="success"
                                                    @click="checkDiscoveryURL()" />
                                            </InputGroup>
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="accesstoken">Access Token Url</label>
                                            <InputText id="accesstoken" type="text"
                                                v-model="action.item.request.auth.oauth2[9].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="scope">Scope</label>
                                            <InputText id="scope" type="text"
                                                v-model="action.item.request.auth.oauth2[6].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="authurl">Authorization endpoint</label>
                                            <InputText id="authurl" type="text"
                                                v-model="action.item.request.auth.oauth2[12].value" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- isAString -->
                            <div v-if="itsAString && !errorOnResult" class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-3">
                                            <label for="testresult">Test Result</label>
                                            <InputText id="testresult" type="text" v-model="action.testresult"
                                                disabled />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="deformat1">Deformat ?</label>
                                            <Dropdown id="granttype1" v-model="action.deformat.deformatstatus"
                                                :options="deformatOptions" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="deformatresult">Final Result</label>
                                            <InputText id="deformatresult" type="text" v-model="deformatedResult"
                                                disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- has Result but Error Received -->
                            <div v-if="responseAvailable && errorOnResult" class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="block">
                                            <VueJsonPretty :selectedItem="response"
                                                :jsonPrettyName="selectedResponsePrettyName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Variables & Headers">
                            <div class="grid">
                                <div class="col-12 md:col-6">
                                    <ActionVariables />
                                </div>
                                <div class="col-12 md:col-6">
                                    <ActionHeaders />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Parms / Query">
                            <div class="grid">
                                <div class="col-12 md:col-6 justify">
                                    <ActionQuery />
                                </div>
                                <div v-if="bodyFormdata" class="col-12 md:col-6 justify">
                                    <ActionFormData />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Results & Logs">
                            <div class="card">
                                <DataTable v-model:selection="selectedItem" v-model:filters="filters" :value="responses"
                                    selectionMode="single" dataKey="_id" paginator stripedRows :rows="10"
                                    :rowsPerPageOptions="[10, 20, 50]" tableStyle="min-width: 50rem" filterDisplay="row"
                                    :rowClass="rowClass" :isLoaded="isLoaded"
                                    :globalFilterFields="['logRefNumber', 'clientRef', 'code']">
                                    <template #header>
                                        <div class="flex flex-wrap items-center justify-between gap-2">
                                            <span class="text-xl font-bold">Action Response</span>
                                            <IconField>
                                                <InputIcon>
                                                    <i class="pi pi-search" />
                                                </InputIcon>
                                                <InputText v-model="filters['global'].value"
                                                    placeholder="Keyword Search" />
                                            </IconField>
                                        </div>
                                    </template>

                                    <template #empty> No Responses found. </template>
                                    <template #loading> Loading Response data. Please wait. </template>
                                    <Column field="createdAt" header="Date" sortable>
                                        <template #body="{ data }">
                                            {{ useDateFormat(data.createdAt) }}
                                        </template>
                                    </Column>
                                    <Column field="responseTime" header="Response Time" sortable></Column>
                                    <Column field="status" header="Status" sortable></Column>
                                    <Column field="code" header="Code" sortable></Column>
                                    <Column field="uid" header="uid" sortable></Column>
                                    <Column field="logRefNumber" header="logRefNumber" sortable></Column>
                                    <Column style="flex: 0 0 3rem">
                                        <template #body="{ data }">
                                            <Button type="button" style="font-size: 1rem" :disabled="!isLogAvailable"
                                                label="Logs" @click="latestLogs(data.logRefNumber)" />
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            <VueJsonPretty v-if="response !== undefined" :selectedItem="selectedItem"
                                :jsonPrettyName="selectedResponseName" />

                        </TabPanel>
                    </TabView>

                </div>
            </div>
        </div>
    </template>

    <Divider />

    <div class="grid"></div>

    <VueJsonPretty :selectedItem="action" :jsonPrettyName="selectedAPIPrettyName" />
    <VueJsonPretty :selectedItem="responses" :jsonPrettyName="actionResponsesPrettyName" />
</template>
<style>
    .p-card-title {
        font-size: var(--p-card-title-font-size);
        font-weight: var(--p-card-title-font-weight);
        color: blueviolet;
    }


</style>
