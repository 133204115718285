import { Layout, Templates, List } from '@/views/actiontemplates';

export default {
    path: '/actiontemplates',
    component: Layout,
    children: [
        { path: '', component: List },
        { path: 'templates', component: Templates }
    ]
};

// export default {
//     path: '/actiontemplates', component: () => import('@/views/actiontemplates/Layout.vue'),
//     // component: Layout,
//     children: [
//         //  { path: '', component: List },
//         { path: '', component: () => import('@/views/actiontemplates/CollectionList.vue') },
//         { path: 'templates', component: () => import('@/views/actiontemplates/TemplatesList.vue') },
//     ]
// };
