<script setup>
import { reactive, watch, nextTick } from "vue";
import { storeToRefs } from 'pinia';
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { useConnectorsStore, useModelsStore } from "@/stores";
const connectorsStore = useConnectorsStore();
const { connector } = storeToRefs(connectorsStore);
const modelsStore = useModelsStore();
const state = reactive({
  renderOK: true,
  val: JSON.stringify(connector.value.lastresultObject),
  data: connector.value.lastresultObject,
  selectedValue: connector.value.deformat.clusterItems,
  selectableType: "multiple",
  showSelectController: false,
  showLength: false,
  showLine: true,
  showLineNumber: false,
  highlightSelectedNode: true,
  selectOnClickNode: true,
  collapsedOnClickBrackets: true,
  rootPath: "res",
  deep: 1,
  node: {},
  showIcon: false,
});

const handleClusterNodeClick = (node) => {
  console.log("clusterNodeClick ", node);
  let clusterPath = node.path;
  console.log("clusterPath ", clusterPath);
  let clusterKey = node.key;
  let clusterContent = node.content;
  let nodeType = node.type;
  if (nodeType == "content") {
    if (connector.value.deformat.clusterItems.includes(clusterPath)) {
      let index = connector.value.deformat.clusterItems.indexOf(clusterPath);
      let clusterIndex = connector.value.deformat.clusterConfig.findIndex(
        (obj) => obj.jsonpathcmd == clusterPath
      );
      connector.value.deformat.clusterItems.splice(index, 1);
      connector.value.deformat.clusterConfig.splice(clusterIndex, 1);
    } else {
      connector.value.deformat.clusterItems.push(clusterPath);
      let clusterConfig = {};
      clusterConfig.nodename = clusterKey;
      clusterConfig.jsonpathcmd = clusterPath;
      clusterConfig.deformatstatus = false;
      clusterConfig.testresult = clusterContent;
      clusterConfig.clusterjsonpath = clusterPath.replace("[0]", "[p]");
      connector.value.deformat.clusterConfig.push(clusterConfig);
    }
  }
 // connectorsStore.incClusterKey();
  state.node = node;
};

watch(
  () => state.val,
  (newVal) => {
    try {
      state.data = JSON.parse(newVal);
    } catch (err) {
      // console.log('JSON ERROR');
    }
  }
);

watch(
  () => state.selectableType,
  async (newVal) => {
    state.renderOK = false;
    if (newVal === "single") {
      state.selectedValue = "res.error";
    } else if (newVal === "multiple") {
      state.selectedValue = ["res.error", "res.data[0].title"];
    }
    // Re-render because v-model:selectedValue format is different in case 2
    await nextTick();
    state.renderOK = true;
  }
);
</script>

<template>
  <div class="example-box">
    <div class="block">
      <h3>
        Select upto {{ modelsStore.totalInputs }} input fields plus an optional reference field
      </h3>
      <!-- <div>{{ state.selectedValue }}</div>
      <h3>Current Node Click:</h3>
      <div>{{ state.node }}</div> -->
    </div>
    <div class="block">
      <vue-json-pretty
        v-if="state.renderOK"
        v-model:selectedValue="state.selectedValue"
        :data="state.data"
        :root-path="state.rootPath"
        :deep="state.deep"
        :show-double-quotes="true"
        :highlight-selected-node="state.highlightSelectedNode"
        :show-length="state.showLength"
        :show-line="state.showLine"
        :show-line-number="state.showLineNumber"
        :select-on-click-node="state.selectOnClickNode"
        :collapsed-on-click-brackets="state.collapsedOnClickBrackets"
        :node-selectable="(node) => node.type == 'content'"
        :selectable-type="state.selectableType"
        :show-select-controller="state.showSelectController"
        :show-icon="state.showIcon"
        @node-click="handleClusterNodeClick"
      />
    </div>
  </div>
</template>
