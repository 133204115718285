import { Layout, List, Templates } from '@/views/connectortemplates';

export default {
    path: '/connectortemplates',
    component: Layout,
    children: [
        { path: '', component: List },
        { path: 'templates', component: Templates },
    ]
};
