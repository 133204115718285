<script setup>
    import { storeToRefs } from 'pinia';
    import { ref, watch, computed, onMounted } from 'vue';
    import { useConnectorsStore, useModelsStore, useTokensetsStore } from '@/stores';
    import { useRoute } from 'vue-router';
    import { router } from '@/router';
    import { FilterMatchMode } from 'primevue/api';
    import { VueJsonPretty, VueJsonCluster, VueJsonPath, ConnectorVariables, ConnectorHeaders, ConnectorQuery, ConnectorFormData } from '@/components';
    import { useDateFormat, useMarkdown } from '@/composables';
    import { useToast } from 'primevue/usetoast';
    const toast = useToast();
    const route = useRoute();
    const id = route.params.id;
    const selectedAPIPrettyName = ref('Connector Details');
    const selectedResponsePrettyName = ref('Response Details');
    const selectedResponseName = ref('Selected Response');
    const connectorsStore = useConnectorsStore();
    const modelsStore = useModelsStore();
    const tokensetsStore = useTokensetsStore();
    const { tokensets, tokenset } = storeToRefs(tokensetsStore);
    const { createToken, updateToken, getTokenset, getTokensets } = tokensetsStore;
    const { clusterTest } = modelsStore;
    const {
        // data
        connector,
        response,
        responses,
        filteredInputs,
        // control
        isLoaded,
        clusterTestAllowed,
        testAllowed,
        // status
        connectorItemAllocated,
        discoveryURLOK,
        isTokenManager,
        isTokenClient,
        isAllocated,
        // change indicators
        hostChanged,
        pathChanged,
        // initial data
        compareHost,
        comparePath,
        // helpers
        itsAnObject,
        itsAString,
        errorOnResult,
        responseAvailable,
        unallocatedCluster,
        isProtocol,
        bodyFormdata,
        bodyJson,
        // auth    
        newAuthSettingType,
        authSettingType,
        oauth2PasswordCredentials,
        oauth2ClientCredentials,
        passwordIndex,
        usernameIndex,
        apivalueIndex,
        apikeyIndex,
        // messaging
        connectorMessages,
        connectorActivePanel,
        // other
        parsedVariables,
        postMethod,
    } = storeToRefs(connectorsStore);
    const { connectorTest, checkConfig, saveConnector, clone, deleteConnector, saveHost, savePath, checkDiscoveryURL, updateConnector, setupTokenManager, createTokenClient } = connectorsStore;
    const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const selectedItem = ref();
    const clusterTestConfig = ref(false);
    const executeActions = ref(false);
    const batchRefNumber = ref('');
    const tokensetAvailable = ref(false);
    // const newTokenParam = ref({
    //     id_token: token.value.id_token ? token.value.id_token : connector.value.name,
    //     access_token: connector.value.lastresult,
    //     expires_in: 1800,
    //     expires_at: Date.now() + (1800 * 1000),
    //     connector: connector.value._id,
    //     userid: connector.value.userid, // 1800 * 1000
    // });
    // const selectedTokenManager = ref({});
    // const tokenVariableKey = ref({});

    onMounted(() => {
        connectorMessages.value = [];
        getTokensets()
    });

    const tokenExpire = computed(() => {
        return useDateFormat(tokenset.value.expires_at);
    });

    const isLogAvailable = (logRefNumber) => {
        return logs.value.some(log => log.meta.stack.logRefNumber === logRefNumber);
    };


    const newtokenValue = () => {
        var mydata = {};
        mydata = connector.value.responseObject.body;
        let tempResult = eval(connector.value.deformat.jsonpathcmd);
        console.log('tempResult = ', tempResult);
        return tempResult;
    };

    const executeClusterTest = () => {
        toast.add({ severity: 'info', summary: 'Decision Cluster Test Started', life: 3000 });
        console.log('Connector.vue executeClusterTest', connector.value._modelid, connector.value._id, batchRefNumber.value, executeActions.value);
        clusterTest(connector.value._modelid, connector.value._id, batchRefNumber.value, executeActions.value);
    };


    const saveClientToken = async () => {
        isLoaded.value = false;
        await createTokenClient();
        console.log('Connector.vue saveClientToken', connector.value._id);
        toast.add({ severity: 'info', summary: 'Token Linked', life: 3000 });
    }

    const protocolOptions = ref([
        { text: 'HTTP', value: 'http' },
        { text: 'HTTPS', value: 'https' }
    ]);

    const editingBody = ref(false);

    const editingCard = ref(false);

    const methodOptions = ref([
        { text: 'GET', value: 'GET' },
        { text: 'POST', value: 'POST' },
        { text: 'PUT', value: 'PUT' },
        { text: 'PATCH', value: 'PATCH' }
    ]);

    const authOptions = ref([
        { text: 'NONE', value: 'noauth' },
        { text: 'BASIC', value: 'basic' },
        { text: 'BEARER', value: 'bearer' },
        { text: 'APIKEY', value: 'apikey' },
        { text: 'OAUTH2', value: 'oauth2' }
    ]);

    const bodyOptions = ref([
        { text: 'NONE', value: 'none' },
        { text: 'JSON', value: 'raw' },
        { text: 'FORMDATA', value: 'formdata' }
    ]);

    const addTokenTo = ref([
        { text: 'Add Token to Header', value: 'header' },
        { text: 'Add Token to Url', value: 'url' }
    ]);

    const clientAuthentication = ref([
        { text: 'Send Basic Auth Header', value: 'header' },
        { text: 'Send in Body', value: 'body' }
    ]);

    const grantType = ref([
        { text: 'Client Authentication', value: 'client_credentials' },
        { text: 'Password Authentication', value: 'password_credentials' }
    ]);

    const deformatOptions = ref([
        { text: 'NO', value: false },
        { text: 'YES', value: true }
    ]);

    const decisionOptions = ref([
        { text: 'STANDARD', value: false },
        { text: 'AI Cluster', value: true }
    ]);

    const tokenManager = ref([
        { text: 'NO', value: false },
        { text: 'YES', value: true }
    ]);

    const tokenClient = ref([
        { text: 'NO', value: false },
        { text: 'YES', value: true }
    ]);

    const selectionTypes = ref([
        { text: 'SINGLE', value: 'single' },
        { text: 'MULTIPLE', value: 'multiple' }
    ]);

    const yesnoOptions = ref([
        { text: 'NO', value: false },
        { text: 'YES', value: true }
    ]);

    const deformatedResult = computed(() => {
        return connectorsStore.connector.deformat.deformatstatus ? parseFloat(connectorsStore.connector.lastresult) : connectorsStore.connector.lastresult;
    });

    const tokenSelected = computed(() => {
        return !(connector.value.tokenset === null);
    });

    const variableSelected = computed(() => {
        return !(connector.value.tokenVariableKey === null);
    });



    const isDecisionCluster = () => {
        return connector.value.deformat.decisionCluster == true ? true : false;
    };

    function formatData(value, formatRequired) {
        if (formatRequired) {
            return parseInt(value);
        } else {
            return value;
        }
    }

    function cancelConfig() {
        connectorsStore.connectorSelected = false;
        router.push({ path: `/connectors` });
    }

    async function latestLogs(id) {
        console.log('latestLogs logReference =', id);
        router.push({ path: `/logs/logref/${id}` });
    }

    const onCellEditComplete = (event) => {
        let { data, newValue, field } = event;
        data[field] = newValue;
    };

    const rowClass = (data) => {
        return [{ 'text-green-500': data.code < 400, 'text-red-500': data.code > 399 }];
    };

    const createTokenManager = async () => {
        isLoaded.value = false;
        await setupTokenManager();
        tokensetAvailable.value = true;
        toast.add({ severity: 'info', summary: 'Token Manager Created', life: 3000 });
        console.log('Connector.vue createTokenManager', connector.value._id, connector.value.tokenset);
    }

    const tokenUpdate = async () => {
        let tokenParam = {};
        tokenParam.access_token = connector.value.lastresult;
        tokenParam.expires_in = 1800;
        tokenParam.expires_at = Date.now() + (1800 * 1000);
        await updateToken(tokenset.value._id, tokenParam);
        toast.add({ severity: 'info', summary: 'Token Updated', life: 3000 });
        console.log('Connector.vue updatedToken', tokenParam);
    }
    const showTokenset = async () => {
        console.log('showTokenset', connector.value.name, connector.value.lastresult);
        await getTokenset(connector.value.tokenset);
        console.log('getTokenset', tokenset.value.id, tokenset.value.id_token);
        tokensetAvailable.value = true;
    }
    watch(newAuthSettingType, (newnewAuthSettingType) => {
        if (newnewAuthSettingType !== connectorsStore.authSettingType) {
            connectorsStore.changeAuth();
        }
    });

    watch(compareHost, (newcompareHost) => {
        if (newcompareHost !== connector.value.item.request.url.host.join('.')) {
            connectorsStore.hostChanged = true;
        } else {
            connectorsStore.hostChanged = false;
        }
    });

    watch(comparePath, (newcomparePath) => {
        if (newcomparePath !== connectorsStore.connector.item.request.url.path.join('/')) {
            connectorsStore.pathChanged = true;
        } else {
            connectorsStore.pathChanged = false;
        }
    });
</script>

<template>
    <!-- info / action Bar -->
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card">
                <div class="flex flex-wrap items-center justify-between gap-2">
                    <span class="text-xl font-bold">Connector Management</span>
                    <Button label="Cancel" severity="help" @click="cancelConfig()" class="mb-2 mr-2" />
                    <Button label=" Update & Test" severity="success" :disabled="!testAllowed"
                        @click="connectorTest(connector._id)" class="mb-2 mr-2" />
                    <Button label="Preview Setup " severity="info" @click="checkConfig(connector._id)" class="mb-2 mr-2" />
                    <Button label="Save" severity="success" @click="saveConnector(connector._id, connector)" class="mb-2 mr-2" />
                    <Button label="Clone" severity="help" @click="clone(id)" class="mb-2 mr-2" />
                    <Button label="Delete " severity="danger" :disabled="connectorItemAllocated"
                        @click="deleteConnector(id)" class="mb-2 mr-2" />
                </div>
            </div>
        </div>
    </div>

    <!-- loading .... -->
    <ProgressSpinner v-if="!isLoaded" aria-label="Loading" />
    <!-- only when model loaded - model info - model inputs -->
    <template v-if="isLoaded">
        <!-- Model Info - logs, full cycle test, decisions,  -->
        <div class="grid">

            <div v-if="!editingCard" class="col-12 md:col-6">
                <div class="card">
                    <Card style="overflow: hidden">
                        <template #header> </template>
                        <template #title>{{ connector.name }}</template>

                        <template #content>
                            <ScrollPanel style="width: 100%; height: 150px">
                                <p class="m-0">
                                <div v-html="useMarkdown(connector.description)"></div>
                                </p>
                            </ScrollPanel>

                        </template>
                        <template #footer>
                            <div class="flex gap-4 mt-1">
                                <Button label="Edit" class="mb-2 mr-2" @click="editingCard = !editingCard" />
                            </div>
                        </template>
                    </Card>
                </div>
            </div>
            <div v-else class="col-12 md:col-6">
                <div class="card">
                    <Card style="overflow: hidden">
                        <template #header> </template>
                        <template #title>
                            <InputText id="name2" type="text" v-model="connector.name" />
                        </template>

                        <template #content>
                            <ScrollPanel style="width: 100%; height: 150px">
                                <Textarea v-model="connector.description" rows="6" cols="50" />
                            </ScrollPanel>

                        </template>
                        <template #footer>
                            <div class="flex gap-4 mt-1">
                                <Button label="Edit" class="mb-2 mr-2" @click="editingCard = !editingCard" />
                            </div>
                        </template>
                    </Card>
                </div>
            </div>
            <div v-if="bodyJson && !editingBody" class="col-12 md:col-6">
                <div class="card">
                    <Card style="overflow: hidden">
                        <template #header> </template>
                        <template #title>JSON Body</template>

                        <template #content>
                            <ScrollPanel style="width: 100%; height: 200px">
                                <p class="m-0">
                                    {{ connector.item.request.body.raw }}
                                </p>
                            </ScrollPanel>

                        </template>
                        <template #footer>
                            <div class="flex gap-4 mt-1">
                                <Button label="Edit" class="mb-2 mr-2" @click="editingBody = !editingBody" />
                            </div>
                        </template>
                    </Card>
                </div>
            </div>
            <div v-if="bodyJson && editingBody" class="col-12 md:col-6">
                <div class="card">
                    <Card style="overflow: hidden">
                        <template #header> </template>
                        <template #title>JSON Body</template>

                        <template #content>
                            <ScrollPanel style="width: 100%; height: 200px">
                                <p class="m-0">
                                    <Textarea v-model="connector.item.request.body.raw" rows="6" cols="50" />
                                </p>
                            </ScrollPanel>

                        </template>
                        <template #footer>
                            <div class="flex gap-4 mt-1">
                                <Button label="Edit" class="mb-2 mr-2" @click="editingBody = !editingBody" />
                            </div>
                        </template>
                    </Card>
                </div>
            </div>

            <div class="col-12">
                <div class="card">
                    <Accordion v-model:activeIndex="connectorActivePanel">
                        <AccordionTab header="Url & Parameters">
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-6">
                                    <label for="rawurl">Raw Url</label>
                                    <InputText id="rawurl" type="text" v-model="connector.rawUrl" disabled
                                        :invalid="hostChanged || pathChanged" />
                                </div>
                                <div class="field col-12 md:col-6">
                                    <label for="rawquery">Raw Query</label>
                                    <InputText id="rawquery" type="text" disabled v-model="connector.rawBaseQuery"
                                        :invalid="hostChanged || pathChanged" />
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Feedback">
                            <transition-group name="p-message" tag="div">
                                <Message v-for="msg of connectorMessages" :key="msg.id" :severity="msg.severity">{{
                                    msg.content }}
                                </Message>
                            </transition-group>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>



            <div class="col-12">
                <div class="card">

                    <TabView>
                        <TabPanel header="Config & Testing">
                            <div class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-2">
                                            <label for="method">Method</label>
                                            <Dropdown id="method" v-model="connector.item.request.method"
                                                :options="methodOptions" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div class="field col-12 md:col-2">
                                            <label for="protocol">Protocol</label>
                                            <Dropdown id="protocol" v-model="connector.item.request.url.protocol"
                                                :options="protocolOptions" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="horizontal-buttons" class="block mb-2">
                                                TimeOut m/s
                                            </label>
                                            <InputNumber v-model="connector.timeOut" inputId="horizontal-buttons"
                                                showButtons buttonLayout="horizontal" :step="1000">
                                                <template #incrementbuttonicon>
                                                    <span class="pi pi-plus" />
                                                </template>
                                                <template #decrementbuttonicon>
                                                    <span class="pi pi-minus" />
                                                </template>
                                            </InputNumber>
                                        </div>
                                        <div v-if="postMethod" class="field col-12 md:col-2">
                                            <label for="bodytype">Body</label>
                                            <Dropdown id="bodytype" v-model="connector.item.request.body.mode"
                                                :options="bodyOptions" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div class="field col-12 md:col-12">
                                            <label for="url1">Url</label>
                                            <InputText id="url1" type="text" disabled v-model="connector.baseUrl" />
                                        </div>
                                        <div class="field col-12 md:col-5">
                                            <label for="host1">Host</label>
                                            <InputText id="host1" type="text" v-model="compareHost"
                                                :invalid="hostChanged" />
                                        </div>
                                        <div class="field col-12 md:col-1">
                                            <Button v-if="hostChanged" severity="success" rounded icon="pi pi-check"
                                                @click="saveHost()" />
                                        </div>
                                        <div class="field col-12 md:col-5">
                                            <label for="path1">Path</label>
                                            <InputText id="path1" type="text" v-model="comparePath"
                                                :invalid="pathChanged" />
                                        </div>
                                        <div class="field col-12 md:col-1">
                                            <Button v-if="pathChanged" severity="success" rounded icon="pi pi-check"
                                                @click="savePath()" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Authorization Control-->
                            <div class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-2">
                                            <label for="authtype1">Authorization</label>
                                            <Dropdown id="authtype1" v-model="newAuthSettingType" :options="authOptions"
                                                optionLabel="text" optionValue="value" placeholder="Select One">
                                            </Dropdown>
                                        </div>
                                        <div v-if="!isTokenManager" class="field col-12 md:col-2">
                                            <label for="tokenclient">Use Token Manager. </label>
                                            <Dropdown id="tokenclient" v-model="connector.tokenClient"
                                                :options="tokenClient" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div v-if="connector.tokenClient" class="field col-12 md:col-2">
                                            <label for="tokensets">Token Manager</label>
                                            <Dropdown id="tokensets" v-model="connector.tokenset" :options="tokensets"
                                                optionLabel="id_token" optionValue="_id"
                                                placeholder="Select Token Manager" class="w-full md:w-56" />
                                        </div>
                                        <div v-if="connector.tokenClient" class="field col-12 md:col-2">
                                            <label for="tokenvariable">Token Variable</label>
                                            <Dropdown id="tokenvariable" v-model="connector.tokenVariableKey"
                                                :options="connector.variable" optionLabel="key" optionValue="key"
                                                placeholder="Token Variable" class="w-full md:w-56" />
                                        </div>
                                        <div v-if="(tokenSelected && variableSelected)" class="field col-12 md:col-2">
                                            <label for="savetoken">Save Token</label>
                                            <Button id="savetoken" label="Save" severity="success" class="mb-2 mr-2"
                                                @click="saveClientToken()" />
                                        </div>
                                        <div v-if="authSettingType == 'oauth2'" class="field col-12 md:col-3">
                                            <label for="granttype1">Grant Type</label>
                                            <Dropdown id="granttype1"
                                                v-model="connector.item.request.auth.oauth2[1].value"
                                                :options="grantType" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>

                                        </div>
                                        <div v-if="authSettingType == 'oauth2' && oauth2ClientCredentials"
                                            class="field col-12 md:col-3">
                                            <label for="clientauth">Client Authentication</label>
                                            <Dropdown id="clientauth"
                                                v-model="connector.item.request.auth.oauth2[8].value"
                                                :options="clientAuthentication" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div v-if="authSettingType == 'oauth2' && oauth2ClientCredentials"
                                            class="field col-12 md:col-3">
                                            <label for="addtoken">Add Token to?</label>
                                            <Dropdown id="addtoken"
                                                v-model="connector.item.request.auth.oauth2[7].value"
                                                :options="addTokenTo" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                    </div>

                                    <!-- Authorization Type  Basic-->
                                    <div v-if="authSettingType == 'basic'" class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-3">
                                            <label for="username1">Username</label>
                                            <InputText id="username1" type="text"
                                                v-model="connector.item.request.auth.basic[usernameIndex].value" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="password1">Password</label>
                                            <InputText id="password1" type="text"
                                                v-model="connector.item.request.auth.basic[passwordIndex].value" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="authorization1">Authorization</label>
                                            <InputText id="authorization1" type="text"
                                                v-model="parsedVariables.authHeaderKey" disabled />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="credentials1">Encoded Credentials</label>
                                            <InputText id="credentials1" type="text"
                                                v-model="parsedVariables.authHeaderValue" disabled />
                                        </div>
                                    </div>

                                    <!-- Authorization Type  Bearer-->
                                    <div v-if="authSettingType == 'bearer'" class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-4">
                                            <label for="token1">Token</label>
                                            <InputText id="token1" type="text"
                                                v-model="connector.item.request.auth.bearer[0].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="authorization2">Authorization</label>
                                            <InputText id="authorization2" type="text"
                                                v-model="parsedVariables.authHeaderKey" disabled />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="token2">Bearer / Token</label>
                                            <InputText id="token2" type="text" v-model="parsedVariables.authHeaderValue"
                                                disabled />
                                        </div>
                                    </div>

                                    <!-- Authorization Type API Key-->
                                    <div v-if="authSettingType == 'apikey'" class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-3">
                                            <label for="apiheader">API Header Key</label>
                                            <InputText id="apiheader" type="text"
                                                v-model="connector.item.request.auth.apikey[apikeyIndex].value" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="apitoken">API Header Value</label>
                                            <InputText id="apitoken" type="text"
                                                v-model="connector.item.request.auth.apikey[apivalueIndex].value" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="apiheader2">API Header</label>
                                            <InputText id="apiheader2" type="text" disabled
                                                v-model="parsedVariables.authHeaderKey" />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="apitoken2">API Token</label>
                                            <InputText id="apitoken2" type="text" disabled
                                                v-model="parsedVariables.authHeaderValue" />
                                        </div>
                                    </div>

                                    <!-- Authorization Type  oauth2-->
                                    <div v-if="authSettingType == 'oauth2'" class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-4">
                                            <label for="clientid1">Client ID</label>
                                            <InputText id="clientid1" type="text"
                                                v-model="connector.item.request.auth.oauth2[5].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="secret1">Client Secret</label>
                                            <InputText id="secret1" type="text"
                                                v-model="connector.item.request.auth.oauth2[4].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="discovery">Auth Endpoint Discovery</label>
                                            <InputGroup>
                                                <InputText id="discovery" type="text" v-model="connector.discoveryURL"
                                                    :invalid="!discoveryURLOK" />
                                                <Button icon="pi pi-check" severity="success"
                                                    @click="checkDiscoveryURL()" />
                                            </InputGroup>
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="accesstoken">Access Token Url</label>
                                            <InputText id="accesstoken" type="text"
                                                v-model="connector.item.request.auth.oauth2[9].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="scope">Scope</label>
                                            <InputText id="scope" type="text"
                                                v-model="connector.item.request.auth.oauth2[6].value" />
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <label for="authurl">Authorization endpoint</label>
                                            <InputText id="authurl" type="text"
                                                v-model="connector.item.request.auth.oauth2[12].value" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- isAString -->
                            <div v-if="itsAString && !connector.deformat.decisionCluster && !errorOnResult"
                                class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-3">
                                            <label for="testresult">Test Result</label>
                                            <InputText id="testresult" type="text" v-model="connector.testresult"
                                                disabled />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="deformat1">Deformat ?</label>
                                            <Dropdown id="granttype1" v-model="connector.deformat.deformatstatus"
                                                :options="deformatOptions" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="deformatresult">Final Result</label>
                                            <InputText id="deformatresult" type="text" v-model="deformatedResult"
                                                disabled />
                                        </div>
                                        <div v-if="isTokenManager && tokensetAvailable" class="field col-12 md:col-4">
                                            <label for="tokenname">Token Name</label>
                                            <InputText disabled id="tokenname" type="text"
                                                v-model="tokenset.id_token" />
                                        </div>
                                        <div v-if="isTokenManager && tokensetAvailable" class="field col-12 md:col-2">
                                            <label for="tokeexpiresat">Expires At</label>
                                            <InputText disabled id="tokeexpiresat" type="text" v-model="tokenExpire" />
                                        </div>
                                        <div v-if="isTokenManager && tokensetAvailable" class="field col-12 md:col-2">
                                            <label for="tokenexpires">Expires</label>
                                            <InputNumber v-model="tokenset.expires_in" inputId="tokenexpires" disabled>
                                            </InputNumber>
                                        </div>
                                        <div v-if="isTokenManager && tokensetAvailable" class="field col-12 md:col-3">
                                            <label for="tokenvalue">Token </label>
                                            <InputGroup>
                                                <InputText id="tokenvalue" type="text" v-model="tokenset.access_token"
                                                    disabled />
                                                <Button icon="pi pi-check" severity="success" @click="tokenUpdate()" />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Object and JSONPath Management-->
                            <div v-if="itsAnObject && !errorOnResult" class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div v-if="!connector.deformat.decisionCluster" class="field col-12 md:col-3">
                                            <label for="jsonpathcmd">JSONPath</label>
                                            <InputText id="jsonpathcmd" type="text"
                                                v-model="connector.deformat.jsonpathcmd" disabled />
                                        </div>
                                        <div class="field col-12 md:col-3">
                                            <label for="connectortype">Connector Type</label>
                                            <Dropdown id="connectortype" v-model="connector.deformat.decisionCluster"
                                                :options="decisionOptions" optionLabel="text" optionValue="value"
                                                placeholder="Select One"></Dropdown>
                                        </div>
                                        <div v-if="!connector.deformat.decisionCluster && itsAString && !isTokenClient && !isTokenManager"
                                            class="field col-12 md:col-3">
                                            <label for="tokenmanager">Create A Token Manager</label>
                                            <Button id="tokenmanager" label="Create A Token Manager" severity="help"
                                                class="mb-2 mr-2" @click="createTokenManager()" />
                                        </div>
                                        <div v-if="isTokenManager && !tokensetAvailable" class="field col-12 md:col-3">
                                            <label for="tokenmanager">Show Token Config</label>
                                            <Button id="tokenmanager" label="Show Token Config" severity="help"
                                                class="mb-2 mr-2" @click="showTokenset()" />
                                        </div>
                                        <div v-if="clusterTestAllowed" class="col-12 md:col-2">
                                            <label for="connectortype">Test Cluster</label>
                                            <Button label="Cluster Test" severity="help" class="mb-2 mr-2"
                                                :disabled="!clusterTestAllowed"
                                                @click="clusterTestConfig = !clusterTestConfig" />
                                        </div>
                                        <div v-if="clusterTestConfig" class="field col-12 md:col-3">
                                            <label for="batchRefNumber">batchRefNumber</label>
                                            <InputText id="batchRefNumber" type="text" v-model="batchRefNumber" />
                                        </div>
                                        <div v-if="clusterTestConfig" class="field col-12 md:col-3">
                                            <label for="actions">Actions</label>
                                            <Dropdown id="actions" v-model="executeActions" :options="yesnoOptions"
                                                optionLabel="text" optionValue="value" placeholder="Select One">
                                            </Dropdown>
                                        </div>
                                        <div v-if="clusterTestConfig" class="col-12 md:col-2">
                                            <label for="executetest">Execute Cluster Test</label>
                                            <Button label="Execute Cluster Test" severity="help" class="mb-2 mr-2"
                                                @click="executeClusterTest()" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Object and JSONPath Management-->
                            <div v-if="itsAnObject && !connector.deformat.decisionCluster && !errorOnResult"
                                class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="block">
                                            Please select the element required from the JSON tree below
                                            <VueJsonPath />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Save and Allocate on Model-->
                            <div v-if="itsAnObject && connector.deformat.decisionCluster && !errorOnResult && unallocatedCluster"
                                class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <p class="blink">Please Save and then Allocate Decision Cluster from Model
                                            maintenance first
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- Select Inputs Cluster-->
                            <div v-if="itsAnObject && connector.deformat.decisionCluster && !errorOnResult && !unallocatedCluster"
                                class="col-12">
                                <div class="card">
                                    <DataTable :value="connector.deformat.clusterConfig" editMode="cell"
                                        @cell-edit-complete="onCellEditComplete" dataKey="inputName" stripedRows>
                                        <template #header>
                                            <div class="flex flex-wrap items-center justify-between gap-2">
                                                <span class="text-xl font-bold">Inputs</span>
                                            </div>
                                        </template>

                                        <template #empty> No Inputs found. </template>
                                        <template #loading> Loading Inputs. Please wait. </template>
                                        <Column header="INPUTVAR" field="name" style="width: 20%">
                                            <template #body="{ data, field }">
                                                {{ data[field] }}
                                            </template>
                                            <template #editor="{ data, field }">
                                                <Dropdown v-model="data[field]" :options="filteredInputs"
                                                    placeholder="Select Input">
                                                </Dropdown>
                                            </template>
                                        </Column>
                                        <Column field="nodename" header="Node Name ">
                                        </Column>
                                        <Column field="jsonpathcmd" header=" JSONPath">
                                        </Column>
                                        <Column field="testresult" header="Value"></Column>
                                    </DataTable>
                                </div>
                            </div>

                            <!-- Object and JSONPath Management-->
                            <div v-if="itsAnObject && connector.deformat.decisionCluster && !errorOnResult && !unallocatedCluster"
                                class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="block">
                                            <VueJsonCluster />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- has Result but Error Received -->
                            <div v-if="responseAvailable && errorOnResult" class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="block">
                                            <VueJsonPretty :selectedItem="response"
                                                :jsonPrettyName="selectedResponsePrettyName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Variables & Headers">
                            <div class="grid">
                                <div class="col-12 md:col-6">
                                    <ConnectorVariables />
                                </div>
                                <div class="col-12 md:col-6">
                                    <ConnectorHeaders />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Query Parms & FormData">
                            <div class="grid">
                                <div class="col-12 md:col-6 justify">
                                    <ConnectorQuery />
                                </div>
                                <div v-if="bodyFormdata" class="col-12 md:col-6 justify">
                                    <ConnectorFormData />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Results & Logs">
                            <div class="card">
                                <DataTable v-model:selection="selectedItem" v-model:filters="filters" :value="responses"
                                    selectionMode="single" dataKey="_id" paginator :rows="10"
                                    :rowsPerPageOptions="[10, 20, 50]" tableStyle="min-width: 50rem" filterDisplay="row"
                                    :isLoaded="isLoaded" :rowClass="rowClass"
                                    :globalFilterFields="['logRefNumber', 'clientRef', 'code']">
                                    <template #header>
                                        <div class="flex flex-wrap items-center justify-between gap-2">
                                            <span class="text-xl font-bold">Connector Response</span>
                                            <IconField>
                                                <InputIcon>
                                                    <i class="pi pi-search" />
                                                </InputIcon>
                                                <InputText v-model="filters['global'].value"
                                                    placeholder="Keyword Search" />
                                            </IconField>
                                        </div>
                                    </template>

                                    <template #empty> No Responses found. </template>
                                    <template #loading> Loading Response data. Please wait. </template>
                                    <Column field="createdAt" header="Date" sortable>
                                        <template #body="{ data }">
                                            {{ useDateFormat(data.createdAt) }}
                                        </template>
                                    </Column>
                                    <Column field="responseTime" header="Response Time" sortable></Column>
                                    <Column field="status" header="Status" sortable></Column>
                                    <Column field="code" header="Code" sortable></Column>
                                    <Column field="uid" header="uid" sortable></Column>
                                    <Column field="logRefNumber" header="logRefNumber" sortable></Column>
                                    <Column style="flex: 0 0 3rem">
                                        <template #body="{ data }">
                                            <Button type="button" style="font-size: 1rem" :disabled="!isLogAvailable"
                                                label="Logs" @click="latestLogs(data.logRefNumber)" />
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            <VueJsonPretty v-if="response !== undefined" :selectedItem="selectedItem"
                                :jsonPrettyName="selectedResponseName" />

                        </TabPanel>
                    </TabView>

                </div>
            </div>
        </div>
    </template>



    <div class="grid"></div>

    <VueJsonPretty :selectedItem="connector" :jsonPrettyName="selectedAPIPrettyName" />
 
</template>
<style>
    .p-card-title {
        font-size: var(--p-card-title-font-size);
        font-weight: var(--p-card-title-font-weight);
        color: blueviolet;
    }

    @keyframes blink {
        50% {
            opacity: 0.0;
        }
    }

    .blink {
        animation: blink 1s step-start 0s infinite;
        color: crimson;
    }
</style>
