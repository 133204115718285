import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { fetchWrapper } from "@/helpers";


const baseUrl = `${import.meta.env.VITE_API_URL}/decisions`;

export const useDecisionsStore = defineStore(
  "decisions",
  () => {
    const decisions = ref([]);
    const selectedDecision = ref({});
    const modelid = ref("");
    const areLoaded = ref(false);
    const isLoaded = ref(false);
    const statistics = ref({});
    const getDecisions = async () => {
      areLoaded.value = false;
      try {
        decisions.value = await fetchWrapper.get(baseUrl);
        await getStatistics();
      } catch (error) {
        console.error("Failed to fetch decisions:", error);
      } finally {
        areLoaded.value = true;
      }
    };
    const _delete = async (id) => {
      const decisionIndex = decisions.value.findIndex((decision) => decision.id === id);
      await fetchWrapper.delete(`${baseUrl}/${id}`);
      decisions.value.splice(decisionIndex, 1);
    };
    const updateFilter = (_newModelId) => {
      modelid.value = _newModelId;
      areLoaded.value = true;
    };
    const filteredDecisions = computed(() => {
      return decisions.value.filter((s) => s.modelid === modelid.value);
    });
    const getStatistics = async () => {
      areLoaded.value = false;
      try {
        statistics.value = await fetchWrapper.get(`${baseUrl}/statistics`);
      } catch (error) {
        console.error("Failed to fetch decisions:", error);
      } finally {
        areLoaded.value = true;
      }
    };
    // Runs the very first time the store is used. i.e., when the store is initialized.
    getDecisions();
    return {
      decisions,
      selectedDecision,
      getDecisions,
      _delete,
      areLoaded,
      modelid,
      updateFilter,
      filteredDecisions,
      getStatistics,
      isLoaded,
      statistics,
    };
  },
  {
    persist: true,
  },
);
