import { storeToRefs } from 'pinia';
import { useActionsStore, useModelsStore, useConnectorsStore } from '@/stores';

// imports at the top of the file, as is standard

const manageResult = (testType, testResult) => {
    const actionsStore = useActionsStore();
    const connectorsStore = useConnectorsStore();
    const modelsStore = useModelsStore();
    const { connectorMessages, connectorMessageCount, connectorActivePanel } = storeToRefs(connectorsStore);
    const { actionMessages, actionMessageCount, actionActivePanel } = storeToRefs(actionsStore);
    const { modelMessages, modelMessageCount, modelActivePanel } = storeToRefs(modelsStore);
    console.log('manageResult parms ', testType, testResult);
    const { testName = 'Function Test', actionsCompleted = 0, actionsConfigured = 0, logRef, resultText = 'No Additional Info', testError = false, inputsTested = true } = testResult;
    console.log('testResult deconstructed', actionsCompleted, logRef, resultText, testError, inputsTested);

    const addModelMessages = () => {
        modelActivePanel.value = 2;
        modelMessages.value = [
            { severity: 'success', content: testName + ' Completed Successfully. Log Reference ' + logRef, id: modelMessageCount.value++ },
            { severity: 'info', content: 'info: ' + resultText + ' Actions Executed ' + actionsCompleted + ' Decision Input Connectors tested ' + inputsTested, id: modelMessageCount.value++ }
        ];
    };

    const addConnectorMessages = () => {
        connectorActivePanel.value = 1;
        connectorMessages.value = [
            { severity: 'success', content: testName + ' Completed Successfully. Log Reference ' + logRef, id: connectorMessageCount.value++ },
            { severity: 'info', content: 'info: ' + resultText + ' Actions Configured to Trigger ' + actionsConfigured + ' Actions Successfully Executed ' + actionsCompleted, id: connectorMessageCount.value++ }
        ];
    };

    const addActionMessages = () => {
        actionActivePanel.value = 1;
        actionMessages.value = [
            { severity: 'success', content: testName + ' Completed Successfully. Log Reference ' + logRef, id: actionMessageCount.value++ },
            { severity: 'info', content: 'info: ' + resultText + ' Actions Executed ' + actionsCompleted, id: actionMessageCount.value++ }
        ];
    };
    if (testType == 'model') {
        addModelMessages();
    }

    if (testType == 'cluster') {
        addConnectorMessages();
    }

    if (testType == 'action') {
        addActionMessages();
    }

    // if (errorOnResult.value) {
    //     await manageResult('model', response.value);
    // } else {
    //     alertStore.clear();
    //     alertStore.success('Succcess! ' + fulltestResult.Result + ' Actions Completed ' + fulltestResult.ActionsCompleted + ' Log Reference ' + fulltestResult.LogRef);
    // }

    // console.log('caller type and response = ', type, response);
    // const alertStore = useAlertStore();
    // alertStore.clear();

    // let errorMessage = 'ERROR received during test. ';
    // let code = response.code;
    // let message = '';

    // console.log('caller type code and response = ', type, code, response);
    // switch (true) {
    //     case type == 'model' && code == 400:
    //         message = errorMessage + 'up2tom Bad model ID. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 401:
    //         message = errorMessage + 'Missing or bad API key. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 402:
    //         message = errorMessage + 'API Key is not in use or has been disabled. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 403:
    //         message = errorMessage + 'No access to model with the specified ID. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 405:
    //         message = errorMessage + 'Method not allowed (GET). Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 422:
    //         message = errorMessage + 'Invalid Decision Inputs See Logs. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 503:
    //         message = errorMessage + 'Service is currently unavailable. Log Reference ' + response.logRefNumber;
    //         break;
    //     default:
    //         // default code block;
    //         if (response.status == 'ECONNABORTED') {
    //             message = errorMessage + 'ECONNABORTED - Connection exceeded timeout. Log Reference ' + response.logRefNumber;
    //         }
    // }
    // alertStore.error(message);
    // return;
};

export { manageResult };
