<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useActionsStore } from '@/stores';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
const actionsStore = useActionsStore();
const toast = useToast();
const filters = ref();
const { action } = storeToRefs(actionsStore);
const { deleteVariable } = actionsStore;
const addNewVariable = ref({});
const initFilters = () => {
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
};
const submitted = ref(false);
const addNewVariableDialog = ref(false);
const openNew = () => {
    addNewVariable.value = {};
    submitted.value = false;
    addNewVariableDialog.value = true;
};
const hideDialog = () => {
    addNewVariableDialog.value = false;
    submitted.value = false;
};
const addVariable = () => {
    submitted.value = true;

    action.value.variable.push(addNewVariable.value);
    toast.add({ severity: 'success', summary: 'Successful', detail: 'addNewVariable Created', life: 3000 });

    addNewVariableDialog.value = false;
    addNewVariable.value = {};
};
initFilters();
</script>
 
<template>
    <div class="card">
        <DataTable v-model:filters="filters" :value="action.variable" paginator :rows="5" selectionMode="single" filterDisplay="menu" stripedRows :globalFilterFields="['value', 'key']">
            <template #header>
                <div class="flex flex-wrap gap-2 items-center justify-between">
                    <h4 class="m-0">Variables</h4>
                    <IconField>
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Search..." />
                    </IconField>
                    <Button label="New" icon="pi pi-plus" severity="help" class="mr-2" @click="openNew" />
                </div>
            </template>
            <template #empty> No Items found. </template>
            <Column field="disabled" header="Disable" style="min-width: 3rem">
                <template #body="{ data }">
                    <Checkbox v-model="data.disabled" :binary="true" />
                </template>
            </Column>
            <Column header="Variable" sortable field="key" style="min-width: 6rem">
                <template #body="{ data }">
                    <InputText v-model="data.key" type="text" placeholder="Search by Variable" />
                </template>
            </Column>
            <Column header="Value" sortable sortField="value" filterField="value" style="min-width: 6rem">
                <template #body="{ data }">
                    <InputText v-model="data.value" type="text" placeholder="Search by Value" />
                </template>
            </Column>
            <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button type="button" icon="pi pi-trash" rounded severity="danger" @click="deleteVariable(data.key)" />
                </template>
            </Column>
        </DataTable>
    </div>
    <Dialog v-model:visible="addNewVariableDialog" :style="{ width: '450px' }" header="Variable Details" :modal="true">
        <div class="flex flex-col gap-6 p-fluid">
            <div>
                <label for="key" class="block font-bold mb-3">Variable</label>
                <InputText id="key" v-model="addNewVariable.key" required="true" autofocus :invalid="submitted && !addNewVariable.key" />
                <small v-if="submitted && !addNewVariable.key" class="text-red-500">Variable name is required.</small>
            </div>
            <div>
                <label for="value" class="block font-bold mb-3">Value</label>
                <InputText id="value" v-model="addNewVariable.value" required="true" autofocus :invalid="submitted && !addNewVariable.value" />
                <small v-if="submitted && !addNewVariable.value" class="text-red-500">Value is required.</small>
            </div>
            <div>
                <span class="block font-bold mb-4">Disabled</span>
                <div class="grid grid-cols-12 gap-4">
                    <div class="flex items-center gap-2 col-span-6">
                        <Checkbox v-model="addNewVariable.disabled" :binary="true" />
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Cancel" icon="pi pi-times" text @click="hideDialog" />
            <Button label="Save" icon="pi pi-check" @click="addVariable" />
        </template>
    </Dialog>
</template>
