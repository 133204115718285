<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useConnectortemplatesStore, useAuthStore, useConnectorsStore } from '@/stores';
import { useMarkdown } from '@/composables';
import { VueJsonPretty } from '@/components';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from 'primevue/api';
const connectortemplatesStore = useConnectortemplatesStore();
const connectorsStore = useConnectorsStore();
const { deleteConnector } = connectortemplatesStore;
const { selectedConnectorcollection } = storeToRefs(connectortemplatesStore);
const authStore = useAuthStore();
const uiListItems = authStore.user.uiListItems;
const connectortemplatesPrettyName = ref('Selected Item Details');
const cloneDeep = (a) => JSON.parse(JSON.stringify(a));
const selecteditem = ref({});
const expandedRows = ref({});
const toast = useToast();
const connectorAdd = (value) => {
    var deformat = {};
    deformat['deformatstatus'] = false;
    deformat['jsonpath'] = false;
    let newConnector = {};
    newConnector = cloneDeep(connectortemplatesStore.selectedConnectorcollection);
    const newItem = Object.assign({}, value);
    newConnector.item = {};
    newConnector['item'] = newItem;
    newConnector.item['description'] = newConnector.item.request.description === 'undefined' ? newConnector.info.folderdescription : newConnector.item.request.description;
    newConnector['deformat'] = deformat;
    newConnector['name'] = newConnector.item.name;
    newConnector['description'] = newConnector.item.description;
    newConnector['userid'] = authStore.user.userid;
    console.log('Templates.vue cloneConnector are ', newConnector);
    connectorsStore.addConnector(newConnector);
    expandedRows.value = null;
    selecteditem.value = {};
};
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});
const itemSelected = computed(() => { return !(Object.entries(selecteditem.value).length === 0)});




const rowClass = (data) => {
    return [{
            'text-green-500': data.request.method === 'GET',
            'text-yellow-500': data.request.method === 'POST',
            'text-cyan-500': data.request.method === 'PUT',
            'text-indigo-500': data.request.method === 'PATCH'
        }];
};

const onRowExpand = (event) => {
    toast.add({ severity: 'info', summary: 'Template Selected', detail: event.data.name, life: 3000 });
    let newObj = {};
    let newId = event.data.id;
    newObj[newId] = true;
    expandedRows.value = newObj;
    selecteditem.value = event.data;
   // console.log('newObj = ', JSON.stringify(newObj));
   /// JSON.stringify({ event.data.id: true,})
};

const onRowCollapse = (event) => {
    toast.add({ severity: 'success', summary: 'Template Deselected', detail: event.data.name, life: 3000 });
    expandedRows.value = null;
    selecteditem.value = {};
};
const loading = ref(false);
const authStatus = (value) => {
    return value.auth !== undefined
        ? value.auth.type
        : value.auth === undefined && !selectedConnectorcollection.auth.length
        ? 'No Inherited Authorization & None on Item'
        : 'Parent has Authorization ' + selectedConnectorcollection.auth[0].type;
};
</script>

<template>

    <div class="col-12">
        <div class="card">
            <DataTable
                :value="selectedConnectorcollection.item"
                v-model:expandedRows="expandedRows"
                v-model:filters="filters"
                dataKey="id"
                @rowExpand="onRowExpand"
                @rowCollapse="onRowCollapse"
                paginator
                stripedRows
                :rows="uiListItems.connectorTemplatesFolder"
                :rowsPerPageOptions="[10, 20, 30]"
                tableStyle="min-width: 50rem"
                filterDisplay="row"
                :loading="loading"
                :rowClass="rowClass"
                :globalFilterFields="['name', 'request.auth.type', 'request.url.raw', 'request.description']"
            >
                <template #header>
                    <div class="flex flex-wrap items-center justify-between gap-2">
                        <span class="text-xl font-bold"> {{ selectedConnectorcollection.folder }} Collection Templates </span>
                        <IconField>
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </IconField>
                        <Button
                            label="Delete Collection"
                            @click="deleteConnector()"
                            severity="danger"
                            class="mr-2"
                            icon="pi pi-minus"
                        />
                        <Button v-if="itemSelected"
                            label="Add Template"
                           @click="connectorAdd(selecteditem)" icon="pi pi-plus" class="mr-2" severity="help"
                        />
                    </div>
                </template>

                <template #empty> No Templates found. </template>
                <template #loading> Loading Template Please wait. </template>
                <Column
                    expander
                    style="width: 5rem"
                />
                <Column
                    field="name"
                    header="Name"
                    sortable
                >
                    <template #body="slotProps">
                        {{ slotProps.data.name }}
                    </template>
                </Column>
                <Column
                    field="request.auth.type"
                    header="Authorization"
                    sortable
                >
                    <!-- <template #body="slotProps">
                        {{ authStatus(slotProps.data.request) }}
                    </template> -->
                </Column>
                <!-- <Column
                    field="request.method"
                    header="Method"
                    sortable
                >
                </Column> -->
                <Column
                    field="request.url.raw"
                    header="Target Url"
                    sortable
                >
                    <template #body="slotProps">
                        {{ slotProps.data.request.url.raw}}
                    </template>
                </Column>
                <template #expansion="slotProps">
                    <div class="grid">
                        <div class="col-12 md:col-6">
                            <div class="card p-fluid">
                                <Card >
                                    <template #title>Description</template>
                                    <template #content>
                                        <ScrollPanel style="width: 100%; height: 400px">
                                            <p class="m-0">
                                                <div v-html="useMarkdown(slotProps.data.request.description)"></div>
                                            </p>
                                        </ScrollPanel>
                                    </template>
                                </Card>
                            </div>
                        </div>
                        <div class="col-12 md:col-6">
                            <div class="card p-fluid">
                                <Card >
                                    <template #title>Authorization & Headers</template>
                                    <template #content>
                                        <ScrollPanel style="width: 100%; height: 400px">
                                            <p class="m-0">
                                                <span> {{ slotProps.data.request.header }} </span>
                                            </p>
                                        </ScrollPanel>
                                    </template>
                                </Card>
                            </div>
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>

    <VueJsonPretty
        :selectedItem="selecteditem"
        :jsonPrettyName="connectortemplatesPrettyName"
    />


</template>
