<script setup>
import { ref, watch } from 'vue';
import {  useAuthStore, useUsersStore } from '@/stores';
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const authStore = useAuthStore();
const usersStore = useUsersStore();
const uiListItems = authStore.user.uiListItems;
const up2tomtoken = authStore.user.up2tomtoken;
const pmtoken = authStore.user.pmtoken;
const apitoken = authStore.user.apitoken;

const dropdownItems = ref([10,20,30]);
const allowSave = ref(false);
const id = authStore.user.id

const saveChanges = () => {
    toast.add({ severity: 'info', summary: 'User Profile Saved', life: 3000 });
    let userParam = {};
    userParam.uiListItems = uiListItems;
    userParam.pmtoken = pmtoken;
    userParam.up2tomtoken = up2tomtoken;
    console.log('Profile2.vue saveChanges', id, userParam);
   usersStore.updateUser(id, userParam);
};

function copyToClipboardAPIToken() {
    toast.add({ severity: 'info', summary: 'Text copied to clipboard', life: 5000 });
    navigator.clipboard.writeText(apitoken).then(
        () => {
            let result = 'Text copied to clipboard';
            setTimeout(() => {
                result = ''; // Clear the result message after 5 seconds
            }, 5000); // 5000 milliseconds = 5 seconds
        },
        (err) => {
            console.log('Could not copy text: ', err);
        }
    );
}

watch(uiListItems, (newValue) => {
    allowSave.value =  true
});

watch(up2tomtoken, (newup2tomtoken) => {
    allowSave.value =  true
});

watch(pmtoken, (newpmtoken) => {
    allowSave.value =  true
});

</script>

<template>
    <div class="grid">

        <div class="col-12">
            <div class="card">
                <h5>Product Configuration </h5>
                <div class="field col-12 md:col-1">
                    <label for="save">Save</label>
                    <Button
                        label="Save"
                        icon="pi pi-upload"
                        @click="saveChanges()"
                        :disabled="!allowSave"
                    />
                </div> 
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-6">
                        <label for="up2tomtoken">up2tom Token </label>
                        <InputText id="up2tomtoken" type="text" v-model="up2tomtoken" />
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="pmtoken">Postman API Token</label>
                        <InputText id="pmtoken" type="text" v-model="pmtoken" />
                    </div>
                    <div class="field col-12 md:col-12">
                        <label for="jwttoken">e2Integrate API Token</label>
                        <InputGroup>
                            <InputText id="jwttoken" type="text" v-model="apitoken" disabled />
                            <Button icon="pi pi-copy" severity="success" @click="copyToClipboardAPIToken"  />
                        </InputGroup>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
                <h5>UI Configuration - Default Number of List Items</h5>
                <div class="field col-12 md:col-1">
                    <label for="save">Save</label>
                    <Button
                        label="Save"
                        icon="pi pi-upload"
                        @click="saveChanges()"
                        :disabled="!allowSave"
                    />
                </div> 
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-2">
                        <label for="actioncollections">Action Collections</label>
                        <Dropdown id="actioncollections" v-model="uiListItems.actionTemplatesCollections" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="actioncollectionitems">Action Collection Items</label>
                        <Dropdown id="actioncollectionitems" v-model="uiListItems.actionTemplatesFolder" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="actionslist">Actions List</label>
                        <Dropdown id="actionslist" v-model="uiListItems.actionsList" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="connectorcollections">Connector Collections</label>
                        <Dropdown id="connectorcollections" v-model="uiListItems.connectorTemplatesCollections" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="connectorcollectionitems">Connector Collection Items</label>
                        <Dropdown id="connectorcollectionitems" v-model="uiListItems.connectorTemplatesFolder" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="connectorlist">Connectors List</label>
                        <Dropdown id="connectorlist" v-model="uiListItems.connectorsList" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="decisionslist">Decisions List</label>
                        <Dropdown id="decisionslist" v-model="uiListItems.decisionsList" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="logslist">Logs List</label>
                        <Dropdown id="logslist" v-model="uiListItems.logsList" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="logsbylogref">Logs By Logref</label>
                        <Dropdown id="logsbylogref" v-model="uiListItems.logsByLogRef" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="modelslist">Models List</label>
                        <Dropdown id="modelslist" v-model="uiListItems.modelsList" :options="dropdownItems"  placeholder="Select One"></Dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
