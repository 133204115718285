import { Layout, Login, Logout } from '@/views/account';
// import { Layout, Login, Register } from '@/views/account';
export default {
    path: '/account',
    component: Layout,
    children: [
        { path: '', redirect: 'login' },
        { path: 'login', component: Login },
        { path: 'logout', component: Logout }
    ]
};
