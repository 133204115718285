import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useAuthStore, useConnectorsStore, useLogsStore } from "@/stores";
import { fetchWrapper, manageResult } from "@/helpers";

const baseUrl = `${import.meta.env.VITE_API_URL}/models`;

export const useModelsStore = defineStore(
  "models",
  () => {
    const models = ref([]);
    const model = ref({});
    const response = ref({});
    const responses = ref([]);
    const decision = ref({});
    const up2tommodels = ref([]);
    const selectedClusterID = ref("");
    const isLoaded = ref(false);
    const loadingNewModels = ref(false);
    const areLoaded = ref(false);
    const modelMessages = ref([]);
    const availableInputs = ref([]);
    let modelMessageCount = ref(0);
    const modelActivePanel = ref(0);
    function showSuccessMessage(message, severity = "success") {
      modelMessages.value = [{ severity: severity, content: message, id: modelMessageCount.value++ }];
      modelActivePanel.value = 2;
    }
    async function init() {
      isLoaded.value = false;
      selectedClusterID.value = "";
    }
    async function getModels() {
      areLoaded.value = false;
      try {
        models.value = await fetchWrapper.get(`${baseUrl}`);
        areLoaded.value = true;
      } catch (error) {
        console.log("error", error);
      }
    }
    async function refreshModelById(id) {
      isLoaded.value = false;
      try {
        model.value = models.value.find((m) => m._id === id);
        await manageInputs();
        isLoaded.value = true;
      } catch (error) {
        console.log("Mdddel.vue error _id", error);
      }
    }
    async function refreshModels(id) {
      try {
        let modelIndex = models.value.findIndex((model) => model._id === id);
        models.value[modelIndex] = model.value;
        await getModelResponses(id);
      } catch (error) {
        console.error("Failed to refreshModels model:", error);
      }
    }
    async function getModel(id) {
      isLoaded.value = false;
      try {
        model.value = await fetchWrapper.get(`${baseUrl}/${id}`);
        decision.value = model.value.attributes.decision === null ? {} : model.value.attributes.decision;
        response.value = model.value.attributes.response === null ? {} : model.value.attributes.response;
        await refreshModels(id);
        await manageInputs();
        isLoaded.value = true;
      } catch (error) {
        console.log("error", error);
      }
    }
    async function getModelResponses(id) {
      try {
        responses.value = await fetchWrapper.get(`${baseUrl}/${id}/responses`);
      } catch (error) {
        console.error("Failed to fetch action responses:", error);
      }
    }
    async function getUp2tomModels(userid, up2tomtoken) {
      loadingNewModels.value = true;
      let modelParam = {};
      modelParam.userid = userid;
      modelParam.up2tomtoken = up2tomtoken;
      try {
        console.log("getUp2tomModels modelParam", modelParam);
        up2tommodels.value = await fetchWrapper.post(`${baseUrl}/up2tommodels`, modelParam);
      } catch (error) {
        console.log("error", error);
      }
      loadingNewModels.value = false;
    }
    async function addModel(modelNew) {
      init();
      isLoaded.value = true;
      try {
        models.value = await fetchWrapper.post(`${baseUrl}/addmodel`, modelNew);
        showSuccessMessage("up2tom Model added to e2Integrate");
      } catch (error) {
        console.log("error", error);
      }
    }
    async function _delete(id) {
      models.value.find((x) => x._id === id).isDeleting = true;
      await fetchWrapper.delete(`${baseUrl}/${id}`);
      models.value = models.value.filter((x) => x._id !== id);
    }
    async function updateModel(id, modelParam) {
      model.value = await fetchWrapper.patch(`${baseUrl}/${id}`, modelParam);
      await refreshModels(id);
    }
    async function changeToWebhook(connector_id, inputname) {
      const connectorsStore = useConnectorsStore();
      let modelParam = {};
      modelParam.model_id = model.value._id;
      modelParam.connector_id = connector_id;
      modelParam.name = inputname;
      try {
        model.value = await fetchWrapper.post(`${baseUrl}/deallocateapi`, modelParam);
        await refreshModels(model.value._id);
        await connectorsStore.getConnector(connector_id);
        showSuccessMessage("Webhook Activated");
      } catch (error) {
        console.log("error", error);
      }
    }
    async function change2API(connector_id, inputname) {
      const connectorsStore = useConnectorsStore();
      let modelParam = {};
      modelParam.model_id = model.value._id;
      modelParam.connector_id = connector_id;
      modelParam.name = inputname;
      try {
        model.value = await fetchWrapper.post(`${baseUrl}/allocateapi`, modelParam);
        await refreshModels(model.value._id);
        await connectorsStore.getConnector(connector_id);
        showSuccessMessage("Connector Allocated");
      } catch (error) {
        console.log("error", error);
      }
    }
    async function unassignCluster(model_id, connector_id) {
      const connectorsStore = useConnectorsStore();
      let modelParam = {};
      modelParam["model_id"] = model_id;
      modelParam["connector_id"] = connector_id;
      try {
        model.value = await fetchWrapper.post(`${baseUrl}/unassigncluster`, modelParam);
        await connectorsStore.getConnector(connector_id);
        connectorsStore.refreshConnectors(connector_id);
        await refreshModels(model.value._id);
        showSuccessMessage("Decision Cluster unassinged from Model ");
      } catch (error) {
        console.log("error", error);
      }
    }
    async function assignCluster(model_id, connector_id, modelid) {
      const connectorsStore = useConnectorsStore();
      let modelParam = {};
      modelParam["model_id"] = model_id;
      modelParam["connector_id"] = connector_id;
      modelParam["modelid"] = modelid;
      try {
        model.value = await fetchWrapper.post(`${baseUrl}/assigncluster`, modelParam);
        connectorsStore.getConnector(connector_id);
        selectedClusterID.value = "";
        await refreshModels(model.value._id);
        showSuccessMessage("Decision Cluster attached to Model ");
      } catch (error) {
        console.log("error", error);
      }
    }
    async function testAllConnectors(model_id) {
      const authStore = useAuthStore();
      const logStore = useLogsStore();
      const userid = authStore.user.userid;
      try {
        isLoaded.value = false;
        let testType = "model";
        let postParam = {};
        postParam.userid = userid;
        postParam.model_id = model_id;
        let testResult = await fetchWrapper.post(`${baseUrl}/testall`, postParam);
        await manageResult(testType, testResult);
        await getModel(model_id);
        await logStore.getLogs();
      } catch (error) {
        console.log("testAllConnectors Error = ", error);
      }
    }
    async function fullCycleTest() {
      const authStore = useAuthStore();
      const userid = authStore.user.userid;
      try {
        isLoaded.value = false;
        let testType = "model";
        let postParam = {};
        postParam.userid = userid;
        postParam.model_id = model.value._id;
        console.log("fullCycleTest params = ", postParam);
        let testResult = await fetchWrapper.post(`${baseUrl}/completecycletest`, postParam);
        await manageResult(testType, testResult);
        await getModel(model.value._id);
        await logStore.getLogs();
      } catch (error) {
        console.log("fullCycleTest Error = ", error);
      }
    }
    async function clusterTest(model_id, cluster_id, batchRefNumber, executeActions) {
      try {
        isLoaded.value = false;
        const authStore = useAuthStore();
        const userid = authStore.user.userid;
        let testType = "cluster";
        let modelParam = {};
        modelParam["userid"] = userid;
        modelParam["model_id"] = model_id;
        modelParam["cluster_id"] = cluster_id;
        modelParam["batchRefNumber"] = batchRefNumber;
        modelParam["executeActions"] = executeActions;
        //  modelParam['renewOthers'] = renewOthers;
        const testResult = await fetchWrapper.post(`${baseUrl}/clustertest`, modelParam);
        await manageResult(testType, testResult);
        await getModel(model.value._id);
        await logStore.getLogs();
      } catch (error) {
        console.log("clusterTest Error = ", error);
      }
    }
    // };
    async function genAllAPIDocs() {}
    async function manageInputs() {
      const inputArray = ["clientRef"];
      for (let i = 0; i < totalInputs.value; i++) {
        const inputName = model.value.attributes.metadata.attributes[i].name;
        inputArray.push(inputName);
      }
      availableInputs.value = inputArray;
    }
    const totalInputs = computed(() => {
      return model.value === undefined ? 0 : model.value.attributes === undefined ? 0 : Object.keys(model.value.attributes.metadata.attributes).length;
    });
    const hasClusterConnector = computed(() => {
      return model.value.attributes === undefined ? false : model.value.attributes.connector == null ? false : true;
    });
    const hasLogs = computed(() => {
      return model.value.attributes === undefined ? false : model.value.attributes.lastLogRef !== undefined ? true : false;
    });
    const decisionAvailable = computed(() => {
      return decision.value._id === undefined ? false : true;
    });
    const errorOnResult = computed(() => {
      return response.value.code === undefined ? false : response.value.code > 399 || response.value.code < 0 ? true : false;
    });
    const clusterSelected = computed(() => {
      return !(selectedClusterID.value == "");
    });
    getModels();
    return {
      models,
      model,
      decision,
      getModels,
      getModel,
      _delete,
      updateModel,
      init,
      changeToWebhook,
      change2API,
      refreshModels,
      refreshModelById,
      hasClusterConnector,
      hasLogs,
      decisionAvailable,
      fullCycleTest,
      totalInputs,
      selectedClusterID,
      testAllConnectors,
      genAllAPIDocs,
      assignCluster,
      unassignCluster,
      clusterTest,
      isLoaded,
      areLoaded,
      up2tommodels,
      getUp2tomModels,
      addModel,
      errorOnResult,
      getModelResponses,
      response,
      responses,
      clusterSelected,
      loadingNewModels,
      modelMessages,
      modelMessageCount,
      modelActivePanel,
      availableInputs,
    };
  },
  {
    persist: true,
  },
);
