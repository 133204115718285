import { storeToRefs } from 'pinia';
import { useActionsStore, useModelsStore, useConnectorsStore } from '@/stores';

// imports at the top of the file, as is standard
 
const manageResponse = (testType, response) => {
    const actionsStore = useActionsStore();
    const connectorsStore = useConnectorsStore();
    const modelsStore = useModelsStore();
    const { connectorMessages, connectorMessageCount, connectorActivePanel } = storeToRefs(connectorsStore);
    const { actionMessages, actionMessageCount, actionActivePanel } = storeToRefs(actionsStore);
    const { modelMessages, modelMessageCount, modelActivePanel } = storeToRefs(modelsStore);


    // "_id":  "originalRequest": "responseTime": "header": "status":  "code": "logRefNumber": "userid": "createdAt": 

    const { responseTime = 0, status = 'error', code, logRefNumber } = response;
    console.log('manageResult parms ', testType, response);
    console.log('response deconstructed', responseTime, status, code, logRefNumber);

    // const addModelMessages = () => {
    //     modelActivePanel.value = 2;
    //     modelMessages.value = [
    //         { severity: 'success', content: testName + ' Completed Successfully. Log Reference ' + logRef, id: modelMessageCount.value++ },
    //         { severity: 'info', content: 'info: ' + resultText + ' Actions Executed ' + actionsCompleted + ' Decision Input Connectors tested ' + inputsTested, id: modelMessageCount.value++ }
    //     ];
    // }; 

    const addConnectorSuccess = () => {
        connectorActivePanel.value = 1;
        connectorMessages.value = [
            { severity: 'success', content: 'Connector Test Completed Successfully. Log Reference ' + logRefNumber, id: connectorMessageCount.value++ },
            { severity: 'info', content: 'info: Response Time ' + responseTime + 'ms Status ' + status + ' Code ' + code, id: connectorMessageCount.value++ }
        ];
    };

    const addConnectorError = () => {
        connectorActivePanel.value = 1;
        connectorMessages.value = [
            { severity: 'error', content: 'Connector Test Error Received. Log Reference ' + logRefNumber, id: connectorMessageCount.value++ },
            { severity: 'warn', content: 'info: Response Time ' + responseTime + 'ms Status ' + status + ' Code ' + code, id: connectorMessageCount.value++ }
        ];
    };

    const addActionSuccess = () => {
        actionActivePanel.value = 1;
        actionMessages.value = [
            { severity: 'success', content: 'Action Test Completed Successfully. Log Reference ' + logRefNumber, id: actionMessageCount.value++ },
            { severity: 'info', content: 'info: Response Time ' + responseTime + 'ms Status ' + status + ' Code ' + code, id: actionMessageCount.value++ }
        ];
    };

    const addActionError = () => {
        actionActivePanel.value = 1;
        actionMessages.value = [
            { severity: 'error', content: 'Action Test Error Received. Log Reference ' + logRefNumber, id: actionMessageCount.value++ },
            { severity: 'warn', content: 'info: Response Time ' + responseTime + 'ms Status ' + status + ' Code ' + code, id: actionMessageCount.value++ }
        ];
    };


    if (testType == 'connector' && code < 400) {
        addConnectorSuccess();
    };

    if (testType == 'connector' && code > 399) {
        addConnectorError();
    };

    if (testType == 'action' && code < 400) {
        addActionSuccess();
    };

    if (testType == 'action' && code > 399) {
        addActionError();
    };
    // if (errorOnResult.value) {
    //     await manageResult('model', response.value);
    // } else {
    //     alertStore.clear();
    //     alertStore.success('Succcess! ' + fulltestResult.Result + ' Actions Completed ' + fulltestResult.ActionsCompleted + ' Log Reference ' + fulltestResult.LogRef);
    // }

    // console.log('caller type and response = ', type, response);
    // const alertStore = useAlertStore();
    // alertStore.clear();

    // let errorMessage = 'ERROR received during test. ';
    // let code = response.code;
    // let message = '';

    // console.log('caller type code and response = ', type, code, response);
    // switch (true) {
    //     case type == 'model' && code == 400:
    //         message = errorMessage + 'up2tom Bad model ID. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 401:
    //         message = errorMessage + 'Missing or bad API key. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 402:
    //         message = errorMessage + 'API Key is not in use or has been disabled. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 403:
    //         message = errorMessage + 'No access to model with the specified ID. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 405:
    //         message = errorMessage + 'Method not allowed (GET). Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 422:
    //         message = errorMessage + 'Invalid Decision Inputs See Logs. Log Reference ' + response.logRefNumber;
    //         break;
    //     case type == 'model' && code == 503:
    //         message = errorMessage + 'Service is currently unavailable. Log Reference ' + response.logRefNumber;
    //         break;
    //     default:
    //         // default code block;
    //         if (response.status == 'ECONNABORTED') {
    //             message = errorMessage + 'ECONNABORTED - Connection exceeded timeout. Log Reference ' + response.logRefNumber;
    //         }
    // }
    // alertStore.error(message);
    // return;
};

export { manageResponse };
