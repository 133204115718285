import { defineStore } from "pinia";
import { ref } from "vue";


export const useAlertStore = defineStore(
  "alert",
  () => {
    const alert = ref({});
    function success(message) {
      alert.value = { message, type: "alert-success" };
    }
    function error(message) {
      alert.value = { message, type: "alert-danger" };
    }
    function clear() {
      alert.value = null;
    }
    return {
      alert,
      success,
      error,
      clear,
    };
  },
  {
    persist: true,
  },
);
