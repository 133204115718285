import { Layout, List, DecisionsByModel } from '@/views/decisions';

export default {
    path: '/decisions',
    component: Layout,
    children: [
        { path: '', component: List },
        { path: 'model/:id', component: DecisionsByModel }
    ]
};


// //import { Layout } from '@/views/decisions';

// export default {
//     path: '/decisions', component: () => import('@/views/decisions/Layout.vue'),
//     // path: '/decisions',
//     // component: Layout,
//     children: [
//         // { path: '', component: List },
//         { path: '', component: () => import('@/views/decisions/List.vue') },
//         //   { path: 'model', component: DecisionsByModel }
//         { path: 'model', component: () => import('@/views/decisions/DecisionsByModel.vue') }
//     ]
// };
