
// export default {
//     path: '/actions', component: () => import('@/views/actions/Layout.vue'),
//     children: [
//         { path: '', component: () => import('@/views/actions/List.vue') },
//         { path: ':id', component: () => import('@/views/actions/Action.vue') },
//         { path: 'modelactions', component: () => import('@/views/actions/ModelActions.vue') },
//         { path: 'modelactions/trigger', component: () => import('@/views/actions/ModelActionTriggers.vue') },
//     ]
// };


import { Layout, List, Action, ModelActions, ModelActionTriggers } from '@/views/actions';

export default {
    path: '/actions',
    component: Layout,
    children: [
        { path: '', component: List },
        { path: ':id', component: Action },
        { path: 'modelactions/:id', component: ModelActions },
        { path: 'modelactions/:id/trigger/:trigger', component: ModelActionTriggers }
    ]
};
 