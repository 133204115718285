<script setup>
import { ref, onMounted } from 'vue';
import { useLogsStore, useAuthStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { VueJsonPretty } from '@/components';
import { useDateFormat } from '@/composables';
import { FilterMatchMode } from 'primevue/api';
const authStore = useAuthStore();
const uiListItems = authStore.user.uiListItems;
const logsStore = useLogsStore();
const { logs, areLoaded } = storeToRefs(logsStore);
const { getLogs } = logsStore;
const logsPrettyName = ref('Log Item Details');
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});
const selectedLog = ref();

onMounted(() => {
    areLoaded.value = false;
    getLogs();  
});

const rowClass = (data) => {
    if (data.meta.snapshot === null) {
        return [{'text-color': data.meta.snapshot === null}];
    };
    return [
        {
            'text-blue-500': data.meta.snapshot.data != null,
            'text-green-500': data.meta.snapshot.config != null,
            'text-indigo-500': data.meta.snapshot.update != null,
            'text-yellow-500': data.meta.snapshot.modifications != null,
            'text-cyan-500': data.meta.snapshot.response != null,
            'text-red-500': data.level == 'error'
        }
    ];
};

const exportCSV = () => {
    dt.value.exportCSV();
};

const dt = ref();

</script>

<template>
    <div class="card">
        <DataTable
            v-model:selection="selectedLog"
            v-model:filters="filters"
            :value="logs"
            ref="dt"
            selectionMode="single"
            dataKey="_id"
            paginator
            stripedRows
            :rows="uiListItems.logsList"
            :rowsPerPageOptions="[10, 20, 30]"
            tableStyle="min-width: 50rem"
            filterDisplay="row"
            :loading="!areLoaded"
            :rowClass="rowClass"
            :globalFilterFields="['meta.stack.logRefNumber', 'message', 'meta.stack.controller', 'meta.stack.module', 'meta.stack.submodule']"
        >
            <template #header>
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <span class="text-xl font-bold">Logs</span>
                    <IconField>
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </IconField>
                    <Button icon="pi pi-external-link" severity="help" label="Export" @click="exportCSV($event)" />
                </div>
            </template>

            <template #empty> No Logs found. </template>
            <template #loading> Loading Log data. Please wait. </template>
            <Column field="level" header="Level" sortable></Column>
            <Column field="timestamp" header="TimeStamp" sortable>
                <template #body="{ data }">
                    {{ useDateFormat(data.timestamp) }}
                </template>
            </Column>
            <Column field="message" header="Message" sortable></Column>
            <Column field="meta.stack.logRefNumber" header="logRef" sortable></Column>
            <Column field="meta.stack.controller" header="client/batch Ref" sortable></Column>
            <Column field="meta.stack.module" header="Module" sortable></Column>
            <Column field="meta.stack.submodule" header="Sub-Module" sortable></Column>
            <Column style="flex: 0 0 3rem">
                <template #body="{ data }">
                    <AvatarGroup>
                        <Avatar v-if="!(data.meta.snapshot === null || data.meta.snapshot.data === null)" label="D" class="mr-2" style="background-color: lightblue; color: #1a2551" shape="circle" />
                        <Avatar v-if="!(data.meta.snapshot === null || data.meta.snapshot.config === null)" label="C" class="mr-2" style="background-color: lightsalmon; color: #1a2551" shape="circle" />
                        <Avatar v-if="!(data.meta.snapshot === null || data.meta.snapshot.modifications === null)" label="M" class="mr-2" style="background-color: coral; color: #1a2551" shape="circle" />
                        <Avatar v-if="!(data.meta.snapshot === null || data.meta.snapshot.response === null)" label="R" class="mr-2" style="background-color: lightcoral; color: #1a2551" shape="circle" />
                        <Avatar v-if="!(data.meta.snapshot === null || data.meta.snapshot.update === null)" label="U" class="mr-2" style="background-color: hotpink; color: #1a2551" shape="circle" />
                    </AvatarGroup>
                </template>
            </Column>
        </DataTable>
    </div>
    <VueJsonPretty :selectedItem="selectedLog" :jsonPrettyName="logsPrettyName" />
</template>
