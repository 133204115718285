<!-- eslint-disable vue/no-parsing-error -->
<script setup>
import { onMounted, watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useActiontemplatesStore, useAuthStore } from '@/stores';
import { useRouter } from 'vue-router';
import { useDateFormat, useMarkdown } from '@/composables';
import { VueJsonPretty } from '@/components';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const actiontemplatesStore = useActiontemplatesStore();
const { actiontemplates, selectedActioncollection, actiontemplateMessages, actiontemplateActivePanel, isLoaded } = storeToRefs(actiontemplatesStore);
const { importCollection, getActiontemplates } = actiontemplatesStore;
const authStore = useAuthStore();
const uiListItems = authStore.user.uiListItems;
const actiontemplatesPrettyName = ref('Action Template Collections');
const selectedCollectionPrettyName = ref('Selected Collection');
const router = useRouter();
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});
const postmanOK = ref(false);
const postmanID = ref('');
onMounted(() => {
 //   isLoaded.value = false;
    actiontemplateMessages.value = [];
  //  getActiontemplates();
});


const getAPIQuantity = (value) => {
    return Object.keys(value).length;
};

const getVariableQuantity = (value) => {
    return Object.keys(value).length;
};

const onRowSelect = (event) => {
    console.log('collection selected', event.data);
    toast.add({ severity: 'info', summary: 'Collection Selected', detail: event.data.folder, life: 3000 });
    router.push('/actiontemplates/templates');
};

// single ref
watch(postmanID, (newpostmanID) => {
    postmanOK.value = newpostmanID !== '' && authStore.user.pmtoken !== '';
});


</script>
<template>
    <div class="grid">

        <div class="col-12">
            <div class="card">
                <h5>Postman Action Templates</h5>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-6">
                        <label for="collectionid">Collection ID</label>
                        <InputText
                            id="collectionid"
                            type="text"
                            placeholder="157abd41-34f0-4d83-ae78-b5e7c82d3f2d"
                            v-model="postmanID"
                        />
                    </div>
                    <div class="field col-12 md:col-5">
                        <label for="pmtoken">Postman Token</label>
                        <InputText
                            id="pmtoken"
                            placeholder="PMAK-zzzzzzzzzzzzzzzzzzzzzzzz-yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy"
                            v-model="authStore.user.pmtoken"
                            disabled
                        />
                    </div>
                    <div class="field col-12 md:col-1">
                        <label for="import">Import</label>
                        <Button
                            label="import"
                            icon="pi pi-upload"
                            @click="importCollection(postmanID, authStore.user.pmtoken)"
                            :disabled="!postmanOK"
                        />
                    </div>  
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
                <Accordion v-model:activeIndex="actiontemplateActivePanel">
                    <AccordionTab header="Feedback">
                        <transition-group name="p-message" tag="div">
                            <Message v-for="msg of actiontemplateMessages" :key="msg.id" :severity="msg.severity">{{
                                msg.content }}
                            </Message>
                        </transition-group>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
        <div class="col-12">    
            <div class="card">
                <DataTable
                    v-model:selection="selectedActioncollection"
                    v-model:filters="filters"
                    :value="actiontemplates"
                    selectionMode="single"
                    dataKey="_id"
                    paginator
                    stripedRows
                    :rows="uiListItems.actionTemplatesCollections"
                    :rowsPerPageOptions="[10, 20, 30]"
                    tableStyle="min-width: 50rem"
                    filterDisplay="row"
                    :loading="!isLoaded"
                    @rowSelect="onRowSelect"
                    :globalFilterFields="['collectionname', 'folder', 'folderdescription', 'collectiondescription']"
                >
                    <template #header>
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <span class="text-xl font-bold">Collections</span>
                            <IconField>
                                <InputIcon>
                                    <i class="pi pi-search" />
                                </InputIcon>
                                <InputText
                                    v-model="filters['global'].value"
                                    placeholder="Keyword Search"
                                />
                            </IconField>
                        </div>
                    </template>

                    <template #empty> No Collection found. </template>
                    <template #loading> Loading Collection data. Please wait. </template>
                    <Column
                        field="collectionname"
                        header="Name"
                    ></Column>
                    <Column
                        field="folder"
                        header="Folder"
                        sortable
                    ></Column>
                    <Column
                        field="folderdescription"
                        header="Folder Info"
                    >
                        <template #body="{ data }">
                            <ScrollPanel style="width: 100%; height: 100px">
                                <p class="m-0">
                                    <div v-html="useMarkdown(data.folderdescription)"></div>
                                </p>
                            </ScrollPanel>
                        </template>
                    </Column>
                    <Column
                        field="collectiondescription"
                        header="Collection Description"
                    >
                        <template #body="{ data }">
                            <ScrollPanel style="width: 100%; height: 100px">
                                <p class="m-0">
                                    <div v-html="useMarkdown(data.collectiondescription)"></div>
                                </p>
                            </ScrollPanel>
                        </template>
                    </Column>
                    <Column
                        field="updatedAt"
                        header="Date"
                        sortable
                    >
                        <template #body="{ data }">
                            {{ useDateFormat(data.updatedAt) }}
                        </template>
                    </Column>
                    <Column
                        header="# API"
                        field="item"
                        style="min-width: 10rem"
                    >
                        <template #body="{ data }">
                            <Tag
                                :value="getAPIQuantity(data.item)"
                                style="width: 50px; height: 50px"
                                severity="success"
                            />
                        </template>
                    </Column>
                    <Column
                        header="# Variables"
                        field="variable"
                        style="min-width: 10rem"
                    >
                        <template #body="{ data }">
                            <Tag
                                :value="getVariableQuantity(data.variable)"
                                style="width: 50px; height: 50px"
                                severity="success"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <VueJsonPretty
        :selectedItem="actiontemplates"
        :jsonPrettyName="actiontemplatesPrettyName"
    />
    <VueJsonPretty
        :selectedItem="selectedActioncollection"
        :jsonPrettyName="selectedCollectionPrettyName"
    />
</template>
