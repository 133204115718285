import { Layout, List, Connector } from '@/views/connectors';


export default {
    path: '/connectors',
    component: Layout,
    children: [
        { path: '', component: List },
        { path: ':id', component: Connector }
    ]
};





// export default {
//     path: '/connectors', component: () => import('@/views/connectors/Layout.vue'),
//     children: [
//         { path: '', component: () => import('@/views/connectors/List.vue') },
//         { path: ':id', component: () => import('@/views/connectors/Connector.vue') }
//     ]
// };
