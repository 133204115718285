import { Layout, List, ListByLogRef } from '@/views/logs';

export default {
    path: '/logs',
    component: Layout,
    children: [
        { path: '', component: List },
        { path: 'logref/:id', component: ListByLogRef }
    ]
};



// /// import { Layout } from '@/views/logs';

// export default {
//     path: '/logs', component: () => import('@/views/logs/Layout.vue'),
//     children: [
//         //  { path: '', component: List },
//         { path: '', component: () => import('@/views/logs/List.vue') },
//         // { path: 'logref', component: ListByLogRef }
//         { path: 'logref/:id', component: () => import('@/views/logs/ListByLogRef.vue') }
//     ]
// };
