<script setup>
import { useLayout } from '@/layout/composables/layout';
import { computed } from 'vue';

const { layoutConfig } = useLayout();

const logoUrl = computed(() => {
    return `/layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.png`;
});
</script>

<template>
    <div class="layout-footer">
        <img :src="logoUrl" alt="Logo" height="40" class="mr-2" />
        e2Integrate - the Orchestration layer for up2tom 
        <!-- <span class="font-medium ml-2">e2Tech</span> -->
    </div>
</template>
<style lang="scss" scoped></style>
