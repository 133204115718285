import { defineStore } from "pinia";
import { ref } from "vue";
import { fetchWrapper } from "@/helpers";
import { useAuthStore } from "@/stores";
import { router } from "@/router";
const baseUrl = `${import.meta.env.VITE_API_URL}/actiontemplates`;

export const useActiontemplatesStore = defineStore(
  "actiontemplates",
  () => {
    const actiontemplates = ref([]);
    const actiontemplate = ref({});
    const selectedActioncollection = ref({});
    const postmanOK = ref(false);
    const postmanID = ref("");
    const isLoaded = ref(false);
    const actiontemplateMessages = ref([]);
    let actiontemplateMessageCount = ref(0);
    const actiontemplateActivePanel = ref(0);
    function init() {
      selectedActioncollection.value = {};
      postmanID.value = "";
      postmanOK.value = false;
    }
    async function getActiontemplate(id) {
      isLoaded.value = false;
      try {
        actiontemplate.value = await fetchWrapper.get(`${baseUrl}/${id}`);
        isLoaded.value = true;
      } catch (error) {
        console.error("Failed to refresh action", error);
      }
    }
    async function getActiontemplates() {
      isLoaded.value = false;
      try {
        actiontemplates.value = await fetchWrapper.get(`${baseUrl}`);
      } catch (error) {
        showErrorMessage("Failed to fetch action templates");
      } finally {
        isLoaded.value = true;
      }
    }
    async function deleteAction() {
      const collectionId = selectedActioncollection.value._id;
      try {
        await fetchWrapper.delete(`${baseUrl}/${collectionId}`);
        actiontemplates.value = actiontemplates.value.filter((collection) => collection._id !== collectionId);
        showSuccessMessage("Collection Deleted");
        router.push("/actiontemplates");
      } catch (error) {
        showErrorMessage("Failed to delete collection");
      }
    }

    function showSuccessMessage(message) {
      actiontemplateMessages.value = [
        {
          severity: "success",
          content: message,
          id: actiontemplateMessageCount.value++,
        },
      ];
      actiontemplateActivePanel.value = 0;
    }

    function showErrorMessage(message) {
      actiontemplateMessages.value = [
        {
          severity: "error",
          content: message,
          id: actiontemplateMessageCount.value++,
        },
      ];
      actiontemplateActivePanel.value = 0;
    }
    async function importCollection(postmanID, pmtoken) {
      const authStore = useAuthStore();
      const userid = authStore.user.userid;
      const postman = {
        postmanID: postmanID,
        postmanAPIKey: pmtoken,
        userid,
      };
      try {
        const importStatus = await fetchWrapper.post(`${baseUrl}/importcollection`, postman);
        const { ok = false, message = "An Unknown Error Occured" } = importStatus;
        if (ok) {
          showSuccessMessage(message);
        } else {
          showErrorMessage(message);
        }
      } catch (error) {
        console.error("Failed to fetch importCollection:", error);
      }
    }
    getActiontemplates();
    return {
      actiontemplates,
      actiontemplate,
      selectedActioncollection,
      getActiontemplates,
      getActiontemplate,
      deleteAction,
      postmanOK,
      postmanID,
      importCollection,
      isLoaded,
      init,
      actiontemplateMessages,
      actiontemplateMessageCount,
      actiontemplateActivePanel,
    };
  },
  {
    persist: true,
  },
);
