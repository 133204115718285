import { createRouter, createWebHashHistory } from "vue-router";
import AppLayout from "@/layout/AppLayout.vue";
import { useAuthStore, useAlertStore } from "@/stores";
// import { Home } from '@/views';
import { Dashboard } from "@/views";
import accountRoutes from "./account.routes";
import usersRoutes from "./users.routes";
import decisionsRoutes from "./decisions.routes";
import logsRoutes from "./logs.routes";
import actiontemplatesRoutes from "./actiontemplates.routes";
import actionsRoutes from "./actions.routes";
import connectortemplatesRoutes from "./connectortemplates.routes";
import connectorsRoutes from "./connectors.routes";
import modelsRoutes from "./models.routes";
// import responsesRoutes from './responses.routes';
export const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      component: AppLayout,
      children: [
        // {
        //     path: '/',
        //     name: 'dashboard',
        //     component: () => import('@/views/Dashboard.vue')
        // },
        {
          path: "/",
          name: "dashboard",
          component: Dashboard,
        },
        { ...accountRoutes },
        { ...usersRoutes },
        { ...decisionsRoutes },
        { ...logsRoutes },
        { ...connectortemplatesRoutes },
        { ...connectorsRoutes },
        { ...actiontemplatesRoutes },
        { ...actionsRoutes },
        { ...modelsRoutes },
        // { ...responsesRoutes },
        // catch all redirect to home page
        { path: "/:pathMatch(.*)*", redirect: "/" },
        // {
        //     path: '/connectortemplates',
        //     name: 'connectortcollection',
        //     component: () => import('@/views/connectortemplates/CollectionList.vue')
        // },
        // {
        //     path: '/connectortemplates/templates',
        //     name: 'connectortemplates',
        //     component: () => import('@/views/connectortemplates/TemplatesList.vue')
        // },
        // {
        //     path: '/connectors',
        //     name: 'connectors',
        //     component: () => import('@/views/connectors/List.vue')
        // },
        // {
        //     path: '/connectors/:id',
        //     name: 'connector',
        //     component: () => import('@/views/connectors/Connector.vue')
        // },
        // {
        //     path: '/models',
        //     name: 'models',
        //     component: () => import('@/views/models/List.vue')
        // },
        // {
        //     path: '/models/:id',
        //     name: 'model',
        //     component: () => import('@/views/models/Model.vue')
        // },
        // {
        //     path: '/models/up2tommodels',
        //     name: 'up2tommodels',
        //     component: () => import('@/views/models/Up2tomModels.vue')
        // },
        // {
        //     path: '/decisions',
        //     name: 'decisions',
        //     component: () => import('@/views/decisions/List.vue')
        // },
        // {
        //     path: '/decisions/model/:id',
        //     name: 'decisionsbymodel',
        //     component: () => import('@/views/decisions/DecisionsByModel.vue')
        // },
        // {
        //     path: '/actiontemplates',
        //     name: 'actioncollection',
        //     component: () => import('@/views/actiontemplates/CollectionList.vue')
        // },
        // {
        //     path: '/actiontemplates/templates',
        //     name: 'actiontemplates',
        //     component: () => import('@/views/actiontemplates/TemplatesList.vue')
        // },
        // {
        //     path: '/actions',
        //     name: 'actions',
        //     component: () => import('@/views/actions/List.vue')
        // },
        // {
        //     path: '/actions/:id',
        //     name: 'action',
        //     component: () => import('@/views/actions/Action.vue')
        // },
        // {
        //     path: '/actions/modelactions/:id',
        //     name: 'modelactions',
        //     component: () => import('@/views/actions/ModelActions.vue')
        // },
        // {
        //     path: '/actions/modelactions/:id/trigger/:trigger',
        //     name: 'modelactiontriggers',
        //     component: () => import('@/views/actions/ModelActionTriggers.vue')
        // },
        // {
        //     path: '/logs',
        //     name: 'logs',
        //     component: () => import('@/views/logs/List.vue')
        // },
        // {
        //     path: '/logs/logref/:id',
        //     name: 'logref',
        //     component: () => import('@/views/logs/ListByLogRef.vue')
        // }
      ],
    },
    // {
    //     path: '/account/login',
    //     name: 'login',
    //     component: () => import('@/views/account/Login.vue')
    // },
    // {
    //     path: '/account/logout',
    //     name: 'logout',
    //     component: () => import('@/views/account/Logout.vue')
    // }
  ],
});

router.beforeEach((to) => {
  // clear alert on route change
  const alertStore = useAlertStore();
  alertStore.clear();

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/account/login", "/account/register"];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();

  if (authRequired && !authStore.user) {
    // authStore.returnUrl = to.fullPath;
    return "/account/login";
  }
});

//export default router;
