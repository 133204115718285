<script setup>
import { ref } from 'vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
const props = defineProps({
    selectedItem: Object,
    jsonPrettyName: String
});
const deep = ref(3);
const collapsedNodeLength = ref(3);
</script>

<template>
    <section>
        <p>{{ props.jsonPrettyName }}</p>
        <VueJsonPretty :data="props.selectedItem" :deep="deep" :collapsedNodeLength="collapsedNodeLength"> </VueJsonPretty>
    </section>
</template>
