<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useModelsStore, useActionsStore } from '@/stores';
import { useDateFormat } from '@/composables';
import { router } from '@/router';
import { useMarkdown } from '@/composables';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import { FilterMatchMode } from 'primevue/api';
const toast = useToast();
const modelsStore = useModelsStore();
const actionsStore = useActionsStore();
// const actionToAllocate = ref({});
const showAvailableActions = ref(false);
// const selectedModelAction = ref({});
const { freeActions, actions } = storeToRefs(actionsStore);
const { model, isLoaded } = storeToRefs(modelsStore);
const route = useRoute(); 
const id = route.params.id;
const trigger = parseInt(route.params.trigger);
const selecteditem = ref({});
const expandedRows = ref({});

const rowClass = (data) => {
    return [{
        'text-green-500': data.teststatus === true,
        'text-red-500': data.teststatus === false
    }];
};

const triggerActions = computed(() => {
    return actions.value.filter((s) => s._modelid == id && s.predictionIndex == trigger);
});

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});
const itemSelected = computed(() => { return !(Object.entries(selecteditem.value).length === 0) });

const onRowExpand = (event) => {
    toast.add({ severity: 'info', summary: 'ModelActionTriggers Selected', detail: event.data.name, life: 3000 });
    let newObj = {};
    let newId = event.data.id;
    newObj[newId] = true;
    expandedRows.value = newObj;
    selecteditem.value = event.data;
    console.log('onRowExpand action = ', event.data);
    /// JSON.stringify({ event.data.id: true,})
};

const onRowCollapse = (event) => {
    toast.add({ severity: 'success', summary: 'ModelActionTriggers Deselected', detail: event.data.name, life: 3000 });
    expandedRows.value = null;
    selecteditem.value = {};
};
// function cancelUpdate() {
//     router.push({ path: `/actions/modelactions` });
// }

const allocateAction = () => {
    let actionParam = {};
    actionParam.predictionIndex = trigger;
    actionParam._modelid = id;
    actionParam.modelid = model.value.id;
    actionParam.sequence = Object.keys(triggerActions.value).length + 1;
    actionParam.priority = 1;
    actionParam.timesExecuted = 0;
    console.log('ModelActionTriggers.vue allocateAction are ', actionParam);
    console.log('ModelActionTriggers.vue _id are ', selecteditem.value._id);
    actionsStore.updateAction(selecteditem.value._id, actionParam);
    //  actionsStore.addAction(newAction);
    showAvailableActions.value = false;
    expandedRows.value = null;
    selecteditem.value = {};
};

const onRowSelect = (event) => {
    // console.log('onRowSelect selectedInput active', event);
    toast.add({ severity: 'info', summary: 'Action Selected', detail: event.data.name, life: 3000 });
    actionsStore.refreshActionById(event.data._id);
    console.log('selected action', event.data._id);
    router.push({ path: `/actions/${event.data._id}` });
};
</script>





<template>
    <!-- info / action Bar -->
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card">
                <Button label="Active" severity="success" class="mb-2 mr-2" />
                <Button label="Warning" severity="warning" class="mb-2 mr-2" />
                <Button label="Not Ready" severity="danger" class="mb-2 mr-2" />
                <Button v-if="!showAvailableActions" @click="showAvailableActions = !showAvailableActions"
                    label="Show Action" severity="help" class="mb-2 mr-2" />
            </div>
        </div>
    </div>

    <!-- loading .... -->
    <ProgressSpinner v-if="!isLoaded" aria-label="Loading" />
    <!-- only when model loaded - model info - model inputs -->
    <template v-if="isLoaded && !showAvailableActions">
        <!-- Model Info - logs, full cycle test, decisions,  -->
        <div class="card">
            <Accordion :activeIndex="0">
                <AccordionTab>
                    <template #header>
                        <span> Model Information -  {{  model.attributes.name }}</span>
                    </template>
                    <div class="grid">
                        <div class="col-12 md:col-6">
                            <Card>
                                <template #title>Model Status</template>
                                <template #content>
                                    <p class="m-0">
                                        Data Received -
                                        {{ useDateFormat(model.attributes.inputsLastChange) }}
                                    </p>
                                    <p class="m-0">
                                        Decision Tested -
                                        <i class="pi pi-thumbs-up-fill" style="font-size: 1rem; color: greenyellow"></i>
                                    </p>
                                    <p class="m-0">
                                        Actions Triggered -
                                        {{ useDateFormat(model.attributes.triggerLastChange) }}
                                    </p>
                                    <p class="m-0">
                                        Actions Tested -
                                        <i class="pi pi-thumbs-up-fill" style="font-size: 1rem; color: greenyellow"></i>
                                    </p>
                                </template>
                            </Card>
                        </div>
                        <div class="col-12 md:col-6">
                            <Card>
                                <template #title>Model Info</template>
                                <template #content>
                                    <p class="m-0">id - {{ model.id }}</p>
                                    <p class="m-0">Author - {{ model.attributes.publisher }}</p>
                                    <p class="m-0">
                                        Model Published -
                                        {{ useDateFormat(model.attributes['publish-date']) }}
                                    </p>
                                    <p class="m-0">
                                        Audit Trail LogRef -
                                        {{ model.attributes.lastLogRef }}
                                    </p>
                                </template>
                            </Card>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>

        <Divider />

        <div class="card">
            <DataTable :value="triggerActions" selectionMode="single" paginator stripedRows :rows="10"
                :rowsPerPageOptions="[10, 20, 50]" tableStyle="min-width: 50rem" :loading="!isLoaded"
                :rowClass="rowClass"   @rowSelect="onRowSelect">
                <template #header>
                    <div class="flex flex-wrap items-center justify-between gap-2">
                        <span class="text-xl font-bold"> {{ model.attributes.metadata.prediction.question }} - Decision
                            - {{ model.attributes.metadata.prediction.domain.values[trigger] }}</span>
                    </div>
                </template>

                <template #empty> No Actions Allocated - Please Add. </template>
                <template #loading> Loading Model Input data. Please wait. </template>
                <Column field="priority" header="Priority" sortable> </Column>
                <Column field="name" header="Target Action" sortable></Column>
                <Column field="description" header="Description">
                    <template #body="{ data }">
                        <ScrollPanel style="width: 100%; height: 100px">
                            <p class="m-0">
                            <div v-html="useMarkdown(data.description)"></div>
                            </p>
                        </ScrollPanel>
                    </template>
                </Column>
                <Column header="Last Cfg." sortable>
                    <template #body="{ data }">
                        {{ useDateFormat(data.updatedAt) }}
                    </template>
                </Column>
                <Column field="timesExecuted" header="# Executed"></Column>
                <Column header="Last Executed." sortable>
                    <template #body="{ data }">
                        {{ useDateFormat(data.lastExecuted) }}
                    </template>
                </Column>
            </DataTable>
        </div>
    </template>

    <template v-if="isLoaded && showAvailableActions">
        <div class="col-12">
            <div class="card">
                <DataTable :value="freeActions" v-model:expandedRows="expandedRows" v-model:filters="filters"
                    dataKey="_id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" paginator stripedRows :rows="10"
                    :rowsPerPageOptions="[10, 20, 50]" tableStyle="min-width: 50rem" filterDisplay="row"
                    :loading="!isLoaded" :rowClass="rowClass"
                    :globalFilterFields="['name', 'description', 'folder', 'updatedAt']">

                    <template #header>
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <span class="text-xl font-bold"> Available Actions </span>
                            <IconField>
                                <InputIcon>
                                    <i class="pi pi-search" />
                                </InputIcon>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                            </IconField>
                            <Button v-if="itemSelected" label="Assign Action" @click="allocateAction" icon="pi pi-plus"
                                class="mr-2" severity="help" />
                        </div>
                    </template>

                    <template #empty> No Templates found. </template>
                    <template #loading> Loading Template slotProps.data. Please wait. </template>
                    <Column expander style="width: 5rem" />
                    <Column field="name" header="Name" sortable>
                        <template #body="slotProps">
                            {{ slotProps.data.name.substring(0, 100) }}
                        </template>
                    </Column>
                    <Column field="folder" header="Category" sortable>
                    </Column>
                    <Column header="Last Update." sortable>
                        <template #body="{ data }">
                            {{ useDateFormat(data.updatedAt) }}
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="grid">
                            <div class="col-12 md:col-6">
                                <div class="card p-fluid">
                                    <Card>
                                        <template #title>Description</template>
                                        <template #content>
                                            <ScrollPanel style="width: 100%; height: 400px">
                                                <p class="m-0">
                                                <div v-html="useMarkdown(slotProps.data.description)"></div>
                                                </p>
                                            </ScrollPanel>
                                        </template>
                                    </Card>
                                </div>
                            </div>
                            <!-- <div class="col-12 md:col-6">
                              <div class="card p-fluid">
                                  <Card >
                                      <template #title>Authorization & Headers</template>
                                      <template #content>
                                          <ScrollPanel style="width: 100%; height: 400px">
                                              <p class="m-0">
                                                  <span> {{ slotProps.data.request.auth }} </span>
                                                  <br />
                                                  <span> {{ slotProps.data.request.header }} </span>
                                              </p>
                                          </ScrollPanel>
                                      </template>
                                  </Card>
                              </div>
                          </div> -->
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>

        <!-- <VueJsonPretty
          :selectedItem="selecteditem"
          :jsonPrettyName="actiontemplatesPrettyName"
      /> -->
    </template>
    <!-- <Divider />


<VueJsonPretty :selectedItem="modelsStore.modelPopulated" :jsonPrettyName="modelPopulatedPrettyName" />
<VueJsonPretty :selectedItem="selectedInput" :jsonPrettyName="modelPrettyName" /> -->
</template>
