<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useModelsStore, useActionsStore, useConnectorsStore, useAuthStore, useDecisionsStore, useLogsStore, useAlertStore, useTokensetsStore } from '@/stores';
import { VueJsonPretty } from '@/components';
import { useRouter } from 'vue-router';
import { useMarkdown } from '@/composables';
import { useToast } from 'primevue/usetoast';
// import { router } from '@/router';
import { FilterMatchMode } from 'primevue/api';
const authStore = useAuthStore();
const uiListItems = authStore.user.uiListItems;
const modelsStore = useModelsStore();
const actionsStore = useActionsStore();
const connectorsStore = useConnectorsStore();
const tokensetsStore = useTokensetsStore();
const logsStore = useLogsStore();
const decisionsStore = useDecisionsStore();
const alertStore = useAlertStore();
const { availableConnectors, areLoaded } = storeToRefs(connectorsStore);
const { getConnectors } = connectorsStore;
const { getTokensets } = tokensetsStore;
const connectorsPrettyName = ref('Available Connector Items');
const router = useRouter();
const selecteditem = ref({});
const expandedRows = ref({});
const { alert } = storeToRefs(alertStore);
const toast = useToast();
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});


onMounted(() => {
   // areLoaded.value = false;
    // getConnectors();
    // getTokensets();
});
const itemSelected = computed(() => { return !(Object.entries(selecteditem.value).length === 0)});

watch(alert, (newVal) => {
    if (newVal != null) {
        switch (newVal.type) {
            case 'alert-success':
                console.log('alertstore', newVal);
                toast.add({ severity: 'info', summary: 'Model Selected', detail: newVal.message, life: 3000 });
                return;
            case 'alert-danger':
                console.log('alertstore', newVal);
                toast.add({ severity: 'danger', summary: 'Error Detected', detail: newVal.message, life: 3000 });
                return;
        }
        alertStore.clear();
    }
});


const buttonColor = (teststatus) => {
    return teststatus === true ? 'success' : 'danger'; 
};
const connectorStatus = (teststatus) => {
    return teststatus === true ? 'Tested' : 'Not tested'; 
};


const rowClass = (data) => {
    return [{
            'text-green-500': data.teststatus === true,
            'text-red-500': data.teststatus === false
        }];
};

const onRowExpand = (event) => {
    toast.add({ severity: 'info', summary: 'Connect Selected', detail: event.data.name, life: 3000 });
    let newObj = {};
    let newId = event.data._id;
    newObj[newId] = true;
    expandedRows.value = newObj;
    selecteditem.value = event.data;
   /// JSON.stringify({ event.data.id: true,})
};

const onRowCollapse = (event) => {
    toast.add({ severity: 'success', summary: 'Connector Deselected', detail: event.data.name, life: 3000 });
    expandedRows.value = null;
    selecteditem.value = {};
};


async function manageConnector(selecteditem) {
    toast.add({ severity: 'info', summary: 'Connector Selected', detail: selecteditem.name, life: 3000 });
    console.log('manageConnector getting Connector', selecteditem._id);
    await connectorsStore.getConnector(selecteditem._id);
    router.push({ path: `/connectors/${selecteditem._id}` });
};


</script>

<template>

    <!-- loading .... -->
    <ProgressSpinner v-if="!areLoaded" aria-label="Loading" />
    <!-- only when actions loaded  -->
    <template v-if="areLoaded">
        <div class="col-12">
                <div class="card">
                    <DataTable
                        :value="availableConnectors"
                        v-model:expandedRows="expandedRows"
                        v-model:filters="filters"
                        dataKey="_id"
                        @rowExpand="onRowExpand"
                        @rowCollapse="onRowCollapse"
                        paginator
                        stripedRows
                        :rows="uiListItems.connectorsList"
                        :rowsPerPageOptions="[10, 20, 30]"
                        tableStyle="min-width: 50rem"
                        filterDisplay="row"
                        :loading="!areLoaded"
                        :rowClass="rowClass"
                        :globalFilterFields="['name', 'baseUrl', 'folder', 'description']"
                    >
                        <template #header>
                            <div class="flex flex-wrap items-center justify-between gap-2">
                                <span class="text-xl font-bold"> Unallocated Connectors </span>
                                <IconField>
                                <InputIcon>
                                    <i class="pi pi-search" />
                                </InputIcon>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                            </IconField>
                                <Button v-if="itemSelected"
                                    label="Manage Connector"
                                @click="manageConnector(selecteditem)" icon="pi pi-plus" class="mr-2" severity="help"
                                />
                            </div>
                        </template>

                        <template #empty> No Free Connectors - Pls either Import from Templates or Clone an existing Connector . </template>
                        <template #loading> Loading Connectors. Please wait. </template>
                        <Column
                            expander
                            style="width: 5rem"
                        />
                        <Column
                            field="name"
                            header="Name"
                            sortable
                        >
                        </Column>
                        <Column
                            field="baseUrl"
                            header="Target Url"
                            sortable
                        >
                        </Column>
                        <Column
                            field="folder"
                            header="Collection"
                            sortable
                        >
                        </Column>
                        <Column style="flex: 0 0 3rem" header="Status" sortable>
                            <template #body="slotProps">
                                <Tag :value="connectorStatus(slotProps.data.teststatus)" style="width: 50px; height: 50px" :severity="buttonColor(slotProps.data.teststatus)" />
                            </template>
                        </Column>
                        <template #expansion="slotProps">
                            <div class="grid">
                                <div class="col-12 md:col-6">
                                    <div class="card p-fluid">
                                        <Card >
                                            <template #title>Description</template>
                                            <template #content>
                                                <ScrollPanel style="width: 100%; height: 400px">
                                                    <p class="m-0">
                                                        <div v-html="useMarkdown(slotProps.data.description)"></div>
                                                    </p>
                                                </ScrollPanel>
                                            </template>
                                        </Card>
                                    </div>
                                </div>
                                <!-- <div class="col-12 md:col-6">
                                    <div class="card p-fluid">
                                        <Card >
                                            <template #title>Authorization & Headers</template>
                                            <template #content>
                                                <ScrollPanel style="width: 100%; height: 400px">
                                                    <p class="m-0">
                                                        <span> {{ slotProps.data.item.request.auth }} </span>
                                                        <br />
                                                        <span> {{ slotProps.data.item.request.header }} </span>
                                                    </p>
                                                </ScrollPanel>
                                            </template>
                                        </Card>
                                    </div>
                                </div> -->
                            </div>
                        </template>
                    </DataTable>
                </div>
        </div>
    </template>
    <!-- <div class="col-12">
        <div class="card">
            <DataTable
                v-model:selection="selectedConnector"
                v-model:filters="filters"
                :value="freeConnectors"
                selectionMode="single"
                dataKey="_id"
                paginator
                stripedRows
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50]"
                tableStyle="min-width: 50rem"
                filterDisplay="row"
                :loading="!areLoaded"
                @rowSelect="onRowSelect"
                :globalFilterFields="['name', 'description', 'item.request.url.raw', 'folder']"
            >
                <template #header>
                    <div class="flex flex-wrap items-center justify-between gap-2">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <span class="text-xl font-bold">Connectors</span>
                            <IconField>
                                <InputIcon>
                                    <i class="pi pi-search" />
                                </InputIcon>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                            </IconField>
                        </div>

                        <Button label="Active" severity="success" class="mb-2 mr-2" />
                        <Button label="Warnings" severity="warning" class="mb-2 mr-2" />
                        <Button label="Not Tested " severity="danger" class="mb-2 mr-2" />
                    </div>
                </template>

                <template #empty> No Connectors found - Please Import. </template>
                <template #loading> Loading Connectors. Please wait. </template>
                <Column field="name" header="Name" sortable> </Column>
                <Column
                        field="description"
                        header="Description"
                    >
                        <template #body="{ data }">
                            <ScrollPanel style="width: 100%; height: 200px">
                                <p class="m-0">
                                    <div v-html="useMarkdown(data.description)"></div>
                                </p>
                            </ScrollPanel>
                        </template>
                </Column>
                <Column field="baseUrl" header="Target Url" sortable> </Column>
                <Column field="folder" header="Collection" sortable></Column>
                <Column field="teststatus" header="Status" sortable style="min-width: 10rem">
                    <template #body="{ data }">
                        <Tag :value="data.teststatus ? 'Tested' : 'Not Ready'" style="width: 80px; height: 50px" :severity="buttonColor(data)" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div> -->

    <VueJsonPretty :selectedItem="selecteditem" :jsonPrettyName="connectorsPrettyName" />
</template>
