<script setup>
import { ref, computed, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import {  useActionsStore, useTokensetsStore, useAuthStore } from '@/stores';
import { VueJsonPretty } from '@/components';
import { useRouter } from 'vue-router';
import { useMarkdown } from '@/composables';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from 'primevue/api';
const actionsStore = useActionsStore();
const authStore = useAuthStore();
const uiListItems = authStore.user.uiListItems;
const tokensetsStore = useTokensetsStore();
const { actions, areLoaded } = storeToRefs(actionsStore);
const { getActions, getAction } = actionsStore;
const { getTokensets } = tokensetsStore;
const actionsPrettyName = ref('Available Action Items');
const router = useRouter();
const selecteditem = ref({});
const expandedRows = ref({});
const toast = useToast();
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

onBeforeMount(() => {
    // areLoaded.value = false;
    // getActions();
    // getTokensets();
});



const availableActions = computed(() => {
            return actions.value.filter((s) => s._modelid === null);
});

const itemSelected = computed(() => { return !(Object.entries(selecteditem.value).length === 0)});


const buttonColor = (teststatus) => {
    return teststatus === true ? 'success' : 'danger'; 
};
const actionStatus = (teststatus) => {
    return teststatus === true ? 'Tested' : 'Not tested'; 
};

const rowClass = (data) => {
    return [{
            'text-green-500': data.teststatus === true,
            'text-red-500': data.teststatus === false
        }];
};

const onRowExpand = (event) => {
    toast.add({ severity: 'info', summary: 'Action Selected', detail: event.data.name, life: 3000 });
    let newObj = {};
    let newId = event.data._id;
    newObj[newId] = true;
    expandedRows.value = newObj;
    selecteditem.value = event.data;
   /// JSON.stringify({ event.data.id: true,})
};

const onRowCollapse = (event) => {
    toast.add({ severity: 'success', summary: 'Action Deselected', detail: event.data.name, life: 3000 });
    expandedRows.value = null;
    selecteditem.value = {};
};

async function manageAction(selecteditem) {
    toast.add({ severity: 'info', summary: 'Action Selected', detail: selecteditem.name, life: 3000 });
    await actionsStore.getAction(selecteditem._id);
    router.push({ path: `/actions/${selecteditem._id}` });
};

</script>

<template>

    <!-- loading .... -->
    <ProgressSpinner v-if="!areLoaded" aria-label="Loading" />
    <!-- only when actions loaded  -->
    <template v-if="areLoaded">
        <div class="col-12">
            <div class="card">
                <DataTable
                    :value="availableActions"
                    v-model:expandedRows="expandedRows"
                    v-model:filters="filters"
                    dataKey="_id"
                    @rowExpand="onRowExpand"
                    @rowCollapse="onRowCollapse"
                    paginator
                    stripedRows
                    :rows="uiListItems.actionsList"
                    :rowsPerPageOptions="[10, 20, 30]"
                    tableStyle="min-width: 50rem"
                    filterDisplay="row"
                    :loading="!areLoaded"
                    :rowClass="rowClass"
                    :globalFilterFields="['name', 'baseUrl', 'folder', 'description']"
                >
                    <template #header>
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <span class="text-xl font-bold"> Unallocated Actions </span>
                            <IconField>
                            <InputIcon>
                                <i class="pi pi-search" />
                            </InputIcon>
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </IconField>
                            <Button v-if="itemSelected"
                                label="Manage Selected Action"
                            @click="manageAction(selecteditem)" icon="pi pi-plus" class="mr-2" severity="help"
                            />
                        </div>
                    </template>

                    <template #empty> No Free Actions - Pls either Import from Templates or Clone an existing Action . </template>
                    <template #loading> Loading Actions. Please wait. </template>
                    <Column
                        expander
                        style="width: 5rem"
                    />
                    <Column
                        field="name"
                        header="Name"
                        sortable
                    >
                    </Column>
                    <Column
                        field="baseUrl"
                        header="Target Url"
                        sortable
                    >
                    </Column>
                    <Column
                        field="folder"
                        header="Collection"
                        sortable
                    >
                    </Column>
                    <Column style="flex: 0 0 3rem" header="Status" sortable>
                        <template #body="slotProps">
                            <Tag :value="actionStatus(slotProps.data.teststatus)" style="width: 50px; height: 50px" :severity="buttonColor(slotProps.data.teststatus)" />
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="grid">
                            <div class="col-12 md:col-6">
                                <div class="card p-fluid">
                                    <Card >
                                        <template #title>Description</template>
                                        <template #content>
                                            <ScrollPanel style="width: 100%; height: 400px">
                                                <p class="m-0">
                                                    <div v-html="useMarkdown(slotProps.data.description)"></div>
                                                </p>
                                            </ScrollPanel>
                                        </template>
                                    </Card>
                                </div>
                            </div>
                            <!-- <div class="col-12 md:col-6">
                                <div class="card p-fluid">
                                    <Card >
                                        <template #title>Authorization & Headers</template>
                                        <template #content>
                                            <ScrollPanel style="width: 100%; height: 400px">
                                                <p class="m-0">
                                                    <span> {{ slotProps.data.item.request.auth }} </span>
                                                    <br />
                                                    <span> {{ slotProps.data.item.request.header }} </span>
                                                </p>
                                            </ScrollPanel>
                                        </template>
                                    </Card>
                                </div>
                            </div> -->
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </template>

    <VueJsonPretty :selectedItem="selecteditem" :jsonPrettyName="actionsPrettyName" />
</template>

