import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers";
import { router } from "@/router";

const baseUrl = `${import.meta.env.VITE_API_URL}/auth`;

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem("user")),
    returnUrl: null,
    isE2Tech: false,
    //    chatConfig: null,
  }),
  actions: {
    async login(username, password) {
      try {
        const user = await fetchWrapper.post(`${baseUrl}/signin`, {
          username,
          password,
        });

        // update pinia state
        this.user = user;
        this.isE2Tech =
          user.userid === "6523d27ccd39a6724b4222ee" ||
          user.userid === "64fd7a269ce9e2059b5c1345"
            ? true
            : false;
        // let config = {};
        // config["vars"] = {
        //   xapikey: user.apitoken,
        // };
        // this.chatConfig = config;

        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));

        // redirect to previous url or default to home page
        router.push(this.returnUrl || "/");
      } catch (error) {
        console.log("error", error);
      }
    },
    logout() {
      this.user = null;
      this.isE2Tech = false;
      localStorage.removeItem("user");
      localStorage.removeItem("actiontemplates");
      localStorage.removeItem("actions");
      localStorage.removeItem("connectors");
      localStorage.removeItem("connectortemplates");
      localStorage.removeItem("decisions");
      localStorage.removeItem("logs");
      localStorage.removeItem("models");
      localStorage.removeItem("responses");
      localStorage.removeItem("alert");
      localStorage.removeItem("tokensets");
      router.push("/account/login");
    },
  },
});
