<!-- eslint-disable prettier/prettier -->
<script setup>
import { ref, onMounted } from 'vue';
import { useDecisionsStore, useLogsStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { VueJsonPretty } from '@/components';
import { useDateFormat } from '@/composables';
import { FilterMatchMode } from 'primevue/api';
import { router } from '@/router';
import { useRoute } from "vue-router";
const logsStore = useLogsStore();
const { logs } = storeToRefs(logsStore);
const decisionsPrettyName = ref('Decision Items');
const decisionsStore = useDecisionsStore();
const { filteredDecisions, selectedDecision, areLoaded } = storeToRefs(decisionsStore);
const { updateFilter } = decisionsStore
const route = useRoute();
const id = route.params.id;
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

onMounted(() => {
    areLoaded.value = false;
    updateFilter(id);
});

const to2decimals = (value) => {
    return parseFloat(value).toFixed(2);
};

const logsAvailable = (value) => {
    if (logs.value.some((e) => e.meta.stack.logRefNumber === value)) {
        return true;
    } else {
        return false;
    }
};

const exportCSV = () => {
    dt.value.exportCSV();
};

const dt = ref();


const rowClass = (data) => {
    return [{
            'text-green-500': data.attributes['meets-confidence'] == true,
            'text-orange-500': data.attributes['meets-confidence'] == false
        }];
};

const latestLogs = (logRef) => {
    console.log('latestLogs logReference = ', logRef);
    router.push({ path: `/logs/logref/${logRef}` });
};


</script>

<template>
    <div class="card">
        <DataTable
            v-model:selection="selectedDecision"
            v-model:filters="filters"
            :value="filteredDecisions"
            selectionMode="single"
            dataKey="_id"
            paginator
            stripedRows
            :rows="10"
            ref="dt"
            :rowsPerPageOptions="[10, 20, 50]"
            tableStyle="min-width: 50rem"
            filterDisplay="row"
            :loading="!areLoaded"
            :rowClass="rowClass"
            :globalFilterFields="['batchRefNumber', 'clientRef', 'attributes.decision', 'modelid']"
        >
            <template #header>
                <div class="flex flex-wrap items-center justify-between gap-2">
                    <span class="text-xl font-bold">Decisions</span>
                    <IconField>
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </IconField>
                    <Button icon="pi pi-external-link" severity="help" label="Export" @click="exportCSV($event)" />
                </div>
            </template>

            <template #empty> No Decisions found. </template>
            <template #loading> Loading Decision data. Please wait. </template>
            <Column field="modelid" header="Model"></Column>
            <Column field="attributes.decision" header="Decision" sortable></Column>
            <Column field="batchRefNumber" header="batchRef" sortable></Column>
            <Column field="clientRef" header="clientRef" sortable></Column>
            <Column field="createdAt" header="Date" sortable>
                <template #body="{ data }">
                    {{ useDateFormat(data.createdAt) }}
                </template>
            </Column>
            <Column field="attributes.confidence" header="Confidence">
                <template #body="{ data }"> {{ to2decimals(data.attributes.confidence * 100) }} % </template>
            </Column>
            <Column style="flex: 0 0 3rem">
                <template #body="{ data }">
                    <Button type="button" style="font-size: 1rem" :disabled="!logsAvailable" label="Logs" @click="latestLogs(data.logRefNumber)" />
                </template>
            </Column>
        </DataTable>
    </div>
    <VueJsonPretty v-if="decisionsStore.selectedDecision !== undefined" :selectedItem="decisionsStore.selectedDecision" :jsonPrettyName="decisionsPrettyName" />
</template>
