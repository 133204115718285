import { Layout, List, Model, Up2tomModels } from '@/views/models';

export default {
    path: '/models',
    component: Layout,
    children: [
        { path: '', component: List },
        { path: ':id', component: Model },
        { path: 'up2tommodels', component: Up2tomModels }
    ]
};





// export default {
//     path: '/models', component: () => import('@/views/models/Layout.vue'),
//     children: [
//         { path: '', component: () => import('@/views/models/List.vue') },
//         { path: ':id', component: () => import('@/views/models/Model.vue') },
//         { path: 'up2tommodels', component: () => import('@/views/models/Up2tomModels.vue') }
//     ]
// };
