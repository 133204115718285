<script setup>
import { ref, reactive, watch, nextTick } from "vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { useConnectorsStore } from "@/stores";
const connectorsStore = useConnectorsStore();
const nodeArray = ref([]);

const state = reactive({
  renderOK: true,
  val: JSON.stringify(connectorsStore.connector.lastresultObject),
  data: connectorsStore.connector.lastresultObject,
  selectedValue: nodeArray,
  selectableType: "single",
  showSelectController: false,
  showLength: false,
  showLine: true,
  showLineNumber: false,
  highlightSelectedNode: true,
  selectOnClickNode: true,
  collapsedOnClickBrackets: true,
  rootPath: "mydata",
  deep: 1,
  node: "",
  showIcon: false,
});

const handleNodeClick = (node) => {
  console.log("latest node data", node);
  let nodePath = node.path;
  let nodeType = node.type;
  console.log("latest nodepath =", nodePath);
  if (nodeType !== "content") {
    connectorsStore.userMessage = "The selected item is an Object or Arrary please re-select";
  } else {
    connectorsStore.userMessage =
      "The selected item is a string -, with JSONPath and value = " + nodePath + " " + node.content;
    console.log(
      "The selected item is a string - with JSONPath and value = ",
      nodePath,
      node.content
    );
    connectorsStore.connector.deformat.jsonpathcmd = nodePath;
    connectorsStore.connector.deformat.jsonpath = true;
    //connectorsStore.itsAString = true;
    connectorsStore.connector.testResult = node.content;
    console.log("Please click Save to commit the Result");
  }
  state.node = node;
};

watch(
  () => state.val,
  (newVal) => {
    try {
      state.data = JSON.parse(newVal);
    } catch (err) {
      // console.log('JSON ERROR');
    }
  }
);

watch(
  () => state.selectableType,
  async (newVal) => {
    state.renderOK = false;
    if (newVal === "single") {
      state.selectedValue = "res.error";
    } else if (newVal === "multiple") {
      state.selectedValue = ["res.error", "res.data[0].title"];
    }
    // Re-render because v-model:selectedValue format is different in case 2
    await nextTick();
    state.renderOK = true;
  }
);
</script>

<template>
  <div class="example-box">
    <!-- <div>{{ state.selectedValue }}</div>
      <div>{{ state.node }}</div> -->

    <div class="block">
      <vue-json-pretty
        v-if="state.renderOK"
        v-model:selectedValue="state.selectedValue"
        :data="state.data"
        :root-path="state.rootPath"
        :deep="state.deep"
        :show-double-quotes="true"
        :highlight-selected-node="state.highlightSelectedNode"
        :show-length="state.showLength"
        :show-line="state.showLine"
        :show-line-number="state.showLineNumber"
        :select-on-click-node="state.selectOnClickNode"
        :collapsed-on-click-brackets="state.collapsedOnClickBrackets"
        :node-selectable="(node) => node.type == 'content'"
        :selectable-type="state.selectableType"
        :show-select-controller="state.showSelectController"
        :show-icon="state.showIcon"
        @node-click="handleNodeClick"
      />
    </div>
  </div>
</template>
