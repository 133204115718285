import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat' 

// by convention, composable function names start with "use"
export function useDateFormat(value) {
    dayjs.extend(localizedFormat);
    dayjs().format('L LT');
    if (value) {
        return dayjs(value, 'L LT');
    }
}
