import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useAuthStore, useTokensetsStore, useLogsStore  } from "@/stores";
import { fetchWrapper, manageResult, manageResponse } from "@/helpers";
import { useValidHttpUrl } from "@/composables";
import Mustache from "mustache";
import { router } from "@/router";
const baseUrl = `${import.meta.env.VITE_API_URL}/actions`;

export const useActionsStore = defineStore(
  "actions",
  () => {
    const actions = ref([]);
    const action = ref({});
    const actionSelected = ref(false);
    const hostChanged = ref(false);
    const pathChanged = ref(false);
    const isLoaded = ref(false);
    const areLoaded = ref(false);
    const renderOK = ref(true);
    const testAllowed = ref(false);
    const discoveryURLOK = ref(true);
    const compareHost = ref("");
    const comparePath = ref("");
    const userMessage = ref("");
    const authSettingType = ref("");
    const newAuthSettingType = ref("");
    const response = ref({});
    const responses = ref([]);
    const parsedVariables = ref({});
    const modelActionIndex = ref(0);
    const selectedActionID = ref("");
    const actionMessages = ref([]);
    let actionMessageCount = ref(0);
    const actionActivePanel = ref(0);
    function init() {
      console.log("Dev function init");
      selectedActionID.value = "";
      action.value = {};
      response.value = {};
      isLoaded.value = false;
    }
    function showSuccessMessage(message, severity = "success") {
      actionMessages.value = [
        {
          severity: severity,
          content: message,
          id: actionMessageCount.value++,
        },
      ];
      actionActivePanel.value = 1;
    }
    async function getActions() {
      areLoaded.value = false;
      try {
        actions.value = await fetchWrapper.get(`${baseUrl}`);
        areLoaded.value = true;
      } catch (error) {
        console.error("Failed to fetch action:", error);
      }
    }
    async function refreshActions(id) {
      try {
        const actionsIndex = actions.value.findIndex((action) => action._id === id);
        actions.value[actionsIndex] = action.value;
        await getActionResponses(id);
      } catch (error) {
        console.error("Failed to refreshActions action:", error);
      }
    }    
    async function getAction(id) {
      const tokensetsStore = useTokensetsStore();
      isLoaded.value = false;
      try {
        action.value = await fetchWrapper.get(`${baseUrl}/${id}`);
        response.value = action.value.response === null ? {} : action.value.response;
        await refreshActions(id);
        await tokensetsStore.getTokensets();
        await setupAPI();
        isLoaded.value = true;
      } catch (error) {
        console.error("Failed to fetch action:", error);
      }
    }
    async function getActionResponses(id) {
      try {
        responses.value = await fetchWrapper.get(`${baseUrl}/${id}/responses`);
      } catch (error) {
        console.error("Failed to fetch action responses:", error);
      }
    }
    async function updateAction(actionId, updatedAction) {
      try {
        action.value = await fetchWrapper.patch(`${baseUrl}/${actionId}`, updatedAction);
        await refreshActions(action.value._id);
      } catch (error) {
        console.error("Failed to create action:", error);
      }
    }    
    async function refreshActionById(actionId) {
      isLoaded.value = false;
      try {
        action.value = actions.value.find((c) => c._id === actionId);
        await setupAPI();
        isLoaded.value = true;
      } catch (error) {
        console.error("Failed to refresh action:", error);
      }
    }
    async function deleteAction(actionId) {
      try {
        await fetchWrapper.delete(`${baseUrl}/${actionId}`);
        actions.value = actions.value.filter((action) => action._id !== actionId);
        router.push("/actions");
      } catch (error) {
        console.error("Failed to delete action:", error);
      }
    }
    async function addAction(actionData) {
      try {
        const createdAction = await fetchWrapper.post(`${baseUrl}/addaction`, actionData);
        actions.value.push(createdAction);
        showSuccessMessage("Action Added");
      } catch (error) {
        console.error("Failed to create action:", error);
      }
    }
    async function clone(id) {
      try {
        areLoaded.value = false;
        const clonedAction = await fetchWrapper.post(`${baseUrl}/${id}/actionclone`, action.value);
        actions.value = [...actions.value, clonedAction];
        showSuccessMessage("Action Cloned");
        areLoaded.value = true;
        router.push(`/actions`);
      } catch (error) {
        console.error("Failed to clone action:", error);
      }
    }
    async function deallocateAction(actionId) {
      const authStore = useAuthStore();
      const userid = authStore.user.userid;
      try {
        let actionParam = {};
        actionParam["userid"] = userid;
        action.value = await fetchWrapper.post(`${baseUrl}/${actionId}/deallocate`, actionParam);
        await refreshActions(actionId);
        await router.push("/actions");
      } catch (error) {
        console.error("Failed to deallocate action:", error);
      }
    }
    async function checkConfig() {
      actionMessages.value = [];
      try {
        testAllowed.value = true;
        if (authSettingType.value == "bearer") {
          let authToken = renderMustache(action.value.item.request.auth.bearer[0].value);
          parsedVariables.value.authHeaderKey = "Authorization";
          parsedVariables.value.authHeaderValue = "Bearer " + authToken;
        }
        if (authSettingType.value == "basic") {
          let basicPassword = renderMustache(action.value.item.request.auth.basic[passwordIndex.value].value);
          let authToken = basicAuthEncoded(action.value.item.request.auth.basic[usernameIndex.value].value, basicPassword);
          parsedVariables.value.authHeaderKey = "Authorization";
          parsedVariables.value.authHeaderValue = "Basic " + authToken;
        }
        if (authSettingType.value == "apikey") {
          let apiHeaderKey = renderMustache(action.value.item.request.auth.apikey[apikeyIndex.value].value);
          let apiHeaderValue = renderMustache(action.value.item.request.auth.apikey[apivalueIndex.value].value);
          parsedVariables.value.authHeaderKey = apiHeaderKey;
          parsedVariables.value.authHeaderValue = apiHeaderValue;
          console.log("action headers ", apiHeaderKey, apiHeaderValue);
        }
        let targetURL = renderMustache(action.value.item.request.url.raw);
        if (bodyJson.value) {
          let targetBody = renderMustache(action.value.item.request.body.raw);
          action.value.item.request.targetBody = targetBody;
        }
        action.value.item.request.targetURL = targetURL;
        console.log("actionItem.vue checkConfig actionItem   ", action.value.item.request.targetURL, action.value.item.request.targetBody);
        await saveAction(action.value._id, action.value);
        showSuccessMessage("Action Configuration Checked & Saved");
        showSuccessMessage("Please contine to Update & Test", "info");
      } catch (error) {
        console.log("Action.vue checkConfig ERROR ", error);
      }
    }
    async function actionTest(id) {
      actionMessages.value = [];
      const authStore = useAuthStore();
      const userid = authStore.user.userid;
      const logsStore = useLogsStore();
      try {
        let actionParam = {};
        actionParam.userid = userid;
        action.value = await fetchWrapper.post(`${baseUrl}/${id}/test`, actionParam);
        console.log("action test completed", action.value);
        await refreshActions(id);
        await manageResponse("action", response.value);
        await setupAPI();
        await logsStore.getLogs();
        testAllowed.value = false;
        isLoaded.value = true;
      } catch (error) {
        console.log("Action.vue actionTest ERROR ", error);
      }
    }
    async function saveAction(actionId, updatedAction) {
      try {
        action.value = await fetchWrapper.post(`${baseUrl}/${actionId}`, updatedAction);
        console.log("Action variables =", action.value.variable);
        await refreshActions(actionId);
        await setupAPI();
      } catch (error) {
        console.log("Action.vue actionTest ERROR ", error);
      }
    }
    async function saveHost() {
      const updatedHost = renderMustache(compareHost.value);
      const url = `${action.value.item.request.url.protocol}://${updatedHost}`;
      if (!useValidHttpUrl(url)) {
        showSuccessMessage("Invalid new URL", "error");
        return;
      }
      const hostSegments = updatedHost.split(".");
      action.value.item.request.url.host = hostSegments;
      const updatedAction = { ...action.value };
      await saveAction(action.value._id, updatedAction);
      showSuccessMessage("Action host updated");
    }
    async function savePath() {
      const updatedPath = renderMustache(comparePath.value);
      const fullUrl = `${action.value.rawBaseUrl}/${updatedPath}`;

      if (!useValidHttpUrl(fullUrl)) {
        showSuccessMessage("ERROR New Path is Invalid", "error");
      } else {
        const pathSegments = updatedPath.split("/");
        action.value.item.request.url.path = pathSegments;
        const updatedAction = { ...action.value };
        await saveAction(updatedAction._id, updatedAction);
        showSuccessMessage("Success! Action Url Path Updated");
      }
    }
    async function checkDiscoveryURL() {
      const authStore = useAuthStore();
      const userid = authStore.user.userid;
      const discoveryURL = action.value.discoveryURL;
      if (!useValidHttpUrl(discoveryURL)) {
        showSuccessMessage("ERROR New Discovery URL is Invalid", "error");
        discoveryURLOK.value = false;
      } else {
        discoveryURLOK.value = true;
        let postParam = {};
        postParam.discoveryURL = discoveryURL;
        postParam.userid = userid;
        postParam.auth = action.value.item.request.auth;
        const discoveryResult = await fetchWrapper.post(`${baseUrl}/${action.value._id}/checkdiscoveryurl`, postParam);
        const { errorStatus, issuer, metadata = "N/A", errorMsg = "N/A", auth = [] } = discoveryResult;
        if (errorStatus) {
          const { code = "Unknown Error Occured" } = errorMsg;
          showSuccessMessage("ERROR " + code, "error");
        } else {
          action.value.item.request.auth = auth;
          await saveAction(action.value._id, action.value);
          showSuccessMessage("Success! Action OAuth2 Discovery Updated");
        }
      }
    }
    async function createTokenClient() {
      try {
        let actionParam = {};
        actionParam._id = action.value._id;
        actionParam.tokenset = action.value.tokenset;
        actionParam.tokenClient = true;
        actionParam.tokenManager = false;
        actionParam.tokenVariableKey = action.value.tokenVariableKey;
        await updateAction(action.value._id, actionParam);
        isLoaded.value = true;
      } catch (error) {
        console.error("Failed to create token manager:", error);
      }
    }
    async function setupAPI() {
      compareHost.value = action.value.item.request.url.host.join(".");
      comparePath.value = action.value.item.request.url.path.join("/");
      hostChanged.value = false;
      pathChanged.value = false;
      authSettingType.value = action.value.item.request.auth.type;
      newAuthSettingType.value = action.value.item.request.auth.type;
      let body = {};
      body.mode = "none";
      if (action.value.item.request.body === undefined) {
        action.value.item.request.body = body;
      }
    }
    async function changeAuth() {
      const authStore = useAuthStore();
      const userid = authStore.user.userid;
      const auth = action.value.item.request.auth;
      const headers = action.value.item.request.header;
      const newAuthSetting = newAuthSettingType.value;
      const oldAuthSetting = authSettingType.value;
      try {
        const response = await fetchWrapper.post(`${baseUrl}/changeauth`, {
          userid,
          authObj: auth,
          headerObj: headers,
          newAuthSetting,
          oldAuthSetting,
        });
        action.value.item.request.auth = response.newAuthObj;
        authSettingType.value = action.value.item.request.auth.type;
        newAuthSettingType.value = action.value.item.request.auth.type;
        showSuccessMessage("Authorization Type Changed. Please enter new Credentials", "warning");
      } catch (error) {
        console.error("Failed to change auth:", error);
      }
    }
    async function deleteVariable(key) {
      const index = action.value.variable.findIndex((variable) => variable.key === key);
      action.value.variable.splice(index, 1);
      showSuccessMessage("HTTP variable deleted. Please save to commit");
    }
    async function deleteHeader(headerKey) {
      const index = action.value.item.request.header.findIndex((header) => header.key === headerKey);
      action.value.item.request.header.splice(index, 1);
      showSuccessMessage("HTTP header deleted. Please click SAVE to commit");
    }
    async function deleteQuery(queryKey) {
      const index = action.value.item.request.url.query.findIndex((query) => query.key === queryKey);
      action.value.item.request.url.query.splice(index, 1);
      showSuccessMessage("HTTP query deleted. Please click SAVE to commit");
    }
    async function deleteFormdata(formdataKey) {
      const index = action.value.item.request.body.formdata.findIndex((formdata) => formdata.key === formdataKey);
      action.value.item.request.body.formdata.splice(index, 1);
      showSuccessMessage("Formdata field deleted. Please click SAVE to commit");
    }
    function renderMustache(itemToRender) {
      return Mustache.render(itemToRender, action.value.mustachedata);
    }
    function basicAuthEncoded(email, password) {
      const credentials = `${email}:${password}`;
      return btoa(credentials);
    }
    const freeActions = computed(() => {
      return actions.value.filter((s) => s._modelid == null);
    });
    const deformatConfigured = computed(() => {
      return action.value === undefined || action.value == null || isLoaded.value == false ? false : action.value.deformat.deformatstatus;
    });
    const isBody = computed(() => {
      if (isLoaded.value == false) {
        return false;
      } else {
        return !(action.value.item.request.body === undefined);
      }
    });
    const postMethod = computed(() => {
      if (isLoaded.value == false) {
        return false;
      } else {
        return action.value.item.request.method === "POST";
      }
    });
    const bodyFormdata = computed(() => {
      if (isLoaded.value == false) {
        return false;
      } else {
        return action.value.item.request.body.mode === "formdata";
      }
    });
    const bodyJson = computed(() => {
      if (isLoaded.value == false) {
        return false;
      } else {
        return action.value.item.request.body.mode === "raw";
      }
    });
    const isProtocol = computed(() => {
      if (isLoaded.value == false) {
        return false;
      } else {
        return !(action.value.item.request.url.protocol === undefined);
      }
    });
    const responseAvailable = computed(() => {
      return !(response.value._id === undefined);
    });
    const errorOnResult = computed(() => {
      if (response.value.code === undefined) {
        return false;
      }
      if (response.value.code > 399 || response.value.code < 0) {
        return true;
      } else {
        return false;
      }
    });
    const actionItemAllocated = computed(() => {
      if (isLoaded.value == false) {
        return false;
      } else {
        return !(action.value._modelid === undefined || action.value._modelid == null);
      }
    });
    const oauth2PasswordCredentials = computed(() => {
      return action.value.item === undefined
        ? false
        : action.value.item.request.auth.type != "oauth2"
          ? false
          : action.value.item.request.auth.oauth2[1].value == "password_credentials"
            ? true
            : false;
    });
    const oauth2ClientCredentials = computed(() => {
      return action.value.item === undefined ? false : action.value.item.request.auth.type != "oauth2" ? false : action.value.item.request.auth.oauth2[1].value == "client_credentials" ? true : false;
    });
    const passwordIndex = computed(() => {
      return action.value.item === undefined ? 0 : action.value.item.request.auth.type != "basic" ? 0 : action.value.item.request.auth.basic.findIndex((obj) => obj.key == "password");
    });
    const usernameIndex = computed(() => {
      return action.value.item === undefined ? 0 : action.value.item.request.auth.type != "basic" ? 0 : action.value.item.request.auth.basic.findIndex((obj) => obj.key == "username");
    });
    const apivalueIndex = computed(() => {
      return action.value.item === undefined ? 0 : action.value.item.request.auth.type != "apikey" ? 0 : action.value.item.request.auth.apikey.findIndex((item) => item.key === "value");
    });
    const apikeyIndex = computed(() => {
      return action.value.item === undefined ? 0 : action.value.item.request.auth.type != "apikey" ? 0 : action.value.item.request.auth.apikey.findIndex((item) => item.key === "key");
    });
    const isTokenClient = computed(() => {
      return action.value.tokenClient === true;
    });
    const isTokenManager = computed(() => {
      return action.value.tokenManager === true;
    });
    getActions();
    return {
      actions,
      action,
      getActions,
      getAction,
      getActionResponses,
      deleteAction,
      addAction,
      updateAction,
      freeActions,
      deformatConfigured,
      deallocateAction,
      checkConfig,
      actionTest,
      isBody,
      hostChanged,
      pathChanged,
      saveHost,
      savePath,
      isProtocol,
      comparePath,
      compareHost,
      authSettingType,
      newAuthSettingType,
      changeAuth,
      deleteVariable,
      deleteHeader,
      deleteQuery,
      deleteFormdata,
      response,
      responses,
      errorOnResult,
      responseAvailable,
      userMessage,
      parsedVariables,
      renderMustache,
      basicAuthEncoded,
      testAllowed,
      actionItemAllocated,
      saveAction,
      clone,
      isLoaded,
      areLoaded,
      renderOK,
      modelActionIndex,
      oauth2PasswordCredentials,
      oauth2ClientCredentials,
      selectedActionID,
      init,
      passwordIndex,
      usernameIndex,
      apivalueIndex,
      apikeyIndex,
      actionSelected,
      refreshActionById,
      actionMessages,
      actionMessageCount,
      actionActivePanel,
      discoveryURLOK,
      checkDiscoveryURL,
      postMethod,
      bodyFormdata,
      bodyJson,
      createTokenClient,
      isTokenClient,
      isTokenManager,
    };
  },
  {
    persist: true,
  },
);
