import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { fetchWrapper } from '@/helpers';


const baseUrl = `${import.meta.env.VITE_API_URL}/logs`;

export const useLogsStore = defineStore(
    'logs',
    () => {
        const logs = ref([]);
        const logsbyref = ref([]);
        const logRef = ref(0);
        const areLoaded = ref(false);
        const getLogs = async () => {
            try {
                areLoaded.value = false;
                logs.value = await fetchWrapper.get(baseUrl);
            } finally {
                areLoaded.value = true;
            }
        };
        async function getLogsByRef(logRefId) {
            try {
                areLoaded.value = false;
                logsbyref.value = await fetchWrapper.get(`${baseUrl}/logref/${logRefId}`);
            } finally {
                areLoaded.value = true;
            }
        }
        function updateFilter(logRefNumber) {
            logRef.value = Number(logRefNumber);
        }
        const filteredLogs = computed(() => {
            return logs.value.filter((s) => s.meta.stack.logRefNumber === logRef.value);
        });
        // Runs the very first time the store is used. i.e., when the store is initialized.
        getLogs();
        return {
            logs,
            logsbyref,
            logRef,
            getLogs,
            getLogsByRef,
            filteredLogs,
            areLoaded,
            updateFilter
        };
    },
    {
        persist: true
    }
);
