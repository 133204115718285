import { Layout, List, Profile } from '@/views/users';

export default {
    path: '/users',
    component: Layout,
    children: [
        { path: '', component: List },
        { path: 'profile', component: Profile }
    ]
};
